import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: { useNextVariants: true },
  palette: {
    primary: {
      main: '#FAAF56',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#2D3047',
      contrastText: '#ffffff',
    }
  },
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: '10px',
        width: "100%"
      },
      editorContainer: {
        position: 'relative',
        border: "1px solid gray",
        minHeight: '150px',
        maxHeight: '200px',
        overflow: 'auto'
      }
    }
  }
});

export default theme;
