import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';

export default ({tags, body}) => (
    <CardMedia
        component='iframe'
        allow="autoplay; encrypted-media"
        allowFullScreen
        frameBorder='0'
        style={{width: '100%', height:250, padding: 0}}
        src={`https://www.youtube.com/embed/${body.youtubeId}`}
        title={`${tags.join(',')}`}
    />
)