/*global AWS_CONFIG */
/*eslint no-undef: "error"*/
import React, {Component, Suspense} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'typeface-roboto';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import Amplify from 'aws-amplify';

import theme from './themes'

import {
  setUserMetaLoaded,
  setWhgPremiumUser,
  setAdminUser,
  setCuratorUser,
  setTeacherUser
} from './actions';
import API from './api/appsync';
import Header from './components/header';
import SecondaryHeader from './components/secondaryHeader';
import MyRouter from './router';

library.add(faLink)

Amplify.configure(AWS_CONFIG);

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    maxWidth: 1000,
    margin: '0 auto',
    flexDirection: 'column'
  },
  headerRoot: {
    position: 'sticky',
    top: 0,
    paddingBottom: 3,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.background.default
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

class App extends Component {
  async componentDidUpdate() {
    if (this.props.authState === "signedIn") {
      const isAdmin = await API.isInGroup('Admins');
      const isCurator = await API.isInGroup('Curators');
      const isTeacher = await API.isInGroup('Teachers');

      this.props.dispatch(setAdminUser(isAdmin));
      this.props.dispatch(setCuratorUser(isCurator));
      this.props.dispatch(setTeacherUser(isTeacher));

      const userMeta = await API.getUserMeta();

      if(!userMeta) {
        this.props.dispatch(setUserMetaLoaded(true));
        this.props.dispatch(setWhgPremiumUser(false));
        return;
      }

      if(userMeta.hasOwnProperty('whgSubEnd')) {
        const now = Math.floor(Date.now() / 1000);
        if(userMeta['whgSubEnd'] > now) {
          this.props.dispatch(setWhgPremiumUser(true));
        } else {
          this.props.dispatch(setWhgPremiumUser(false));
        }
      }

      this.props.dispatch(setUserMetaLoaded(true));
    }
  }

  render() {
    const {classes} = this.props;

    if (this.props.authState === "signedIn") {
      return (
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Suspense fallback={null}>
              <Router>
                <CssBaseline />
                <div className={classes.headerRoot}>
                  <Header />
                  <SecondaryHeader />
                </div>
                <div className={classes.root}>
                  <MyRouter/>
                </div>
              </Router>
            </Suspense>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      )
    } else {
      return null;
    }
  }
}

export default connect()(withStyles(styles)(App));
