// eslint-disable

export const onCreateItem = `subscription OnCreateItem {
  onCreateItem {
    id
    owner
    createdAt
    credit
    type
    tags
    transcription
    body
    location
    latLon
    status
  }
}
`;
export const onUpdateItem = `subscription OnUpdateItem {
  onUpdateItem {
    id
    owner
    createdAt
    credit
    type
    tags
    transcription
    body
    location
    latLon
    status
  }
}
`;
export const onDeleteItem = `subscription OnDeleteItem {
  onDeleteItem {
    id
    owner
    createdAt
    credit
    type
    tags
    transcription
    body
    location
    latLon
    status
  }
}
`;

export const onCreateTranscript = `subscription onCreateTranscript {
  onCreateTranscript {
      __typename
      itemId
      transcript
      status
    }
}
`;
