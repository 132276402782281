let nextSubscriptionId = 0
export const createSubscription = ({service, expires}) => ({
  type: 'ADD_SUBSCRIPTION',
  id: nextSubscriptionId++,
  service,
  expires
});

export const setUserMetaLoaded = loaded => ({
  type: 'SET_USER_META_LOADED',
  loaded
});

export const setWhgPremiumUser = premium => ({
  type: 'SET_WHG_PREM_USER',
  premium
});

export const setAdminUser = admin => ({
  type: 'SET_ADMIN_USER',
  admin
});

export const setCuratorUser = curator => ({
  type: 'SET_CURATOR_USER',
  curator
});

export const setTeacherUser = teacher => ({
  type: 'SET_TEACHER_USER',
  teacher
});
