const initialState = {
    userMetaLoaded: false,
    isAdmin: false,
    isCurator: false,
    isTeacher: false,
    whgPremium: null,
    subscriptions: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_WHG_PREM_USER':
            return { ...state, whgPremium: action.premium };
        case 'SET_ADMIN_USER':
            return { ...state, isAdmin: action.admin };
        case 'SET_CURATOR_USER':
            return { ...state, isCurator: action.curator };
        case 'SET_TEACHER_USER':
            return { ...state, isTeacher: action.teacher };
        case 'SET_USER_META_LOADED':
            return { ...state, userMetaLoaded: action.loaded };
        case 'ADD_SUBSCRIPTION':
            const {id, service, expires} = action;
            return { ...state, subscriptions: [...state.subscriptions, {id, service, expires}] };
        default:
            return state
    }
}
