/*global AWS_CONFIG */
import React, { Component } from "react";
import Map from "./map";

const GOOGLE_API_KEY = AWS_CONFIG.google_api_key;

class LayoutItemMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: props.initialPinLat || 1.278366,
      lng: props.initialPinLng || 103.853277,
      zoom: props.initialZoom || 12
    }
  }
  onMapClicked = (latLon) => {
    this.props.setPinCords(latLon)
  };

  render() {
    const {lat, lng, zoom} = this.state;
    return (
      <div style={{maxHeight: "500px", minWidth: '100%'}}>
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`}
          loadingElement={<div style={{ height: `100%` }}> Loading </div>}
          containerElement={<div style={{ height: `500px`, minWidth: '100%'}} />}
          mapElement={<div style={{ height:`100%`}} />}
          center={{lat, lng}}
          zoom={zoom}
          onMapClicked={this.onMapClicked}
          isMarkerShown={this.props.isMarkerShown}
        >
        </Map>
      </div>
    );
  }
};

export default LayoutItemMap
