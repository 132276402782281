import React from 'react';
import VideoIcon from '@material-ui/icons/Movie';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardMedia from '@material-ui/core/CardMedia';


const ImageLoading = props => (
    <div>
      <VideoIcon style={{
        width: '100%',
        height: 200,
        color: '#eee'
      }}/>
      <LinearProgress />
    </div>
)

class Video extends React.Component {

    state = {
        videoUrl: null
    }

    async componentWillMount() {
        const { body } = this.props;
        const videoUrl = body.url;
        this.setState({videoUrl});
    }

    render() {
        const {tags} = this.props;
        const {videoUrl} = this.state;
        if(videoUrl === null) {
            return <ImageLoading/>
        }
        return (
            <CardMedia
                component='video'
                width="100%`"
                height="auto"
                controls
                allowFullScreen
                frameBorder='0'
                src={videoUrl}
                style={{height: 250}}
                title={`${tags.join(',')}`}
            />
        )
    }
}

export default Video;