import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';

import LanguageSelector from './../languageIcon/index'
import MyCardMedia from '../termResult/media';
import LayoutItemMap from '../map';
import ItemModal from '../modal/modal';
import RichTextEditor from './../textEditor/writeText';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginRight: '10%',
    marginLeft: '10%'
  },
  gridItem: {
    minWidth: 350
  },
  gridItemsLeft: {
    display: 'flex',
    justifyContent: 'left',
    minWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
  },
  mediaCard: {
    justifyContent: 'left',
    minWidth: '100%'
  },
  table: {
    minWidth: '100%'
  },
  mapContainer: {
    position: 'relative',
    minWidth: '100%'
  },
  mapOverlay: {
    zIndex: 100,
    position: 'absolute',
    background: 'RGBA(255,255,255, 0.4)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  modal: {
    outline: 'none',
    overflowX: 'scroll',
  },
  modalView: {
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    minWidth: '75%',
  },
  modalCard: {
    display: 'flex',
    justifyContent: 'center',
    margin: '100px auto',
    maxWidth: '85%',
  },
  viewButton: {
    padding: 0
  },
  closeModalButton: {
    marginLeft: '90%',
    marginBottom: '-10%'
  },
  modalContainer: {
    marginBottom: theme.spacing(2)
  },
  modalTranscriptionBox: {
    minWidth: '100%',
    padding: theme.spacing(2)
  },
  modalGridItems: {
    margin: theme.spacing(3),
    display: 'flex',
    justifyContent: 'left',
    minWidth: '75%'
  },
  thinBorder: {
    border: 'solid 1px #C0C0C0'
  },
  modalTableBorder: {
    borderWidth: 1,
    borderColor: '#C0C0C0',
    borderStyle: 'solid',
    borderRadius: 16
  },
  chip: {
    marginRight: 8,
    marginTop:4,
    marginBottom:4,
  },
  blockContent: {
    display: 'block'
  },
  floatRight: {
    display: 'flex',
    float: 'right'
  },
  showMapItem: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px 0 20px',
    margin: 0,
    flexDirection: 'row-reverse',
    "& span": {
      fontSize: '0.875rem',
    }
  }
});

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      canEdit: props.canEdit,
      user: props.user,
      edit: false,
      openModal: false,
      mapChecked: false
    };
  }

  renderTags(tags) {
    const {classes} = this.props;
    const {edit} = this.state;
    if(edit === false) {
      return tags.map((tag, idx) => (
        <Chip
          key={`tag-${idx}-${tag}`}
          label={tag}
          color='secondary'
          className={classes.chip}
          onClick={evt => this.props.history.push(`/search/${tag}`)}
        />
      ));
    }
    let {newTag, updating} = this.state;
    return (
      <>
        <TextField
          label="Add Tag"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={newTag}
          disabled={updating}
          fullWidth
          onChange={event => this.setState({newTag: event.target.value})}
          onKeyDown={event => {
            if(event.keyCode === 13) {
              tags.push(newTag);
              this.setState({tags, newTag: ''});
            }
          }}
        />
        {tags.map((tag, idx) => (
          <Chip
            key={`tag-${idx}-${tag}`}
            label={tag}
            onDelete={this.deleteTag.bind(this, tag)}
            className={classes.chip}
          />
        ))}
      </>
    )
  }

  openModal = () => {
    this.setState({openModal: true});
  };

  closeModal = () => {
    this.setState({openModal: false})
  };

  createTranscriptionTableData(charactersBreakdown) {
    const data = charactersBreakdown.map((dataObj) => {
      return {
        name: dataObj.character,
        countInText: dataObj.count,
        percentOfText: dataObj.textPercentile
      }
    });

    return data.sort((a, b) => b.countInText - a.countInText);
  }

  renderTranscriptionBreakdownTable(charactersBreakdown) {
    const {classes, t} = this.props;

    const rowData = this.createTranscriptionTableData(charactersBreakdown);

    return (
      <TableContainer className={classNames(classes.modalTableBorder)}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('item.transcription_modal.table.character')}</TableCell>
              <TableCell align="right">{t('item.transcription_modal.table.count_in_text')}</TableCell>
              <TableCell align="right">{t('item.transcription_modal.table.percent_of_text')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map(row => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right" scope="row">
                  {row.countInText}
                </TableCell>
                <TableCell align="right" scope="row">
                  {row.percentOfText}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  showTranscriptionModal({transcription, charactersBreakdown}) {
    const {classes, t} = this.props;
    const {openModal} = this.state;

    if (!transcription) {
      return t('item.transcription_modal.no_transcription');
    }

    if (charactersBreakdown) {
      charactersBreakdown = JSON.parse(charactersBreakdown);
    }

    return (
      <div>
        <IconButton onClick={this.openModal} className={classes.viewButton}>
          <Typography variant="button">
            {t('item.transcription_modal.button.view')}
          </Typography>
        </IconButton>
        <ItemModal
          classes={classes}
          closeModal={this.closeModal}
          isOpen={openModal}
        >
          <div className={classes.modalView}>
            <Grid container className={classes.gridItem} spacing={2}>
              <Grid item xs={12} className={classNames(classes.modalGridItems)}>
                <IconButton aria-label='CloseModal' onClick={this.closeModal} className={classes.closeModalButton}>
                  <CloseIcon color='secondary'/>
                </IconButton>
              </Grid>
              <Grid item xs={12} className={classNames(classes.modalGridItems)}>
                <Typography variant='h6'>{t('item.transcription_modal.transcription')}</Typography>
              </Grid>
              <Grid item xs={12} className={classNames(classes.modalGridItems)}>
                <Box className={classNames(classes.modalTranscriptionBox, classes.thinBorder)} borderRadius={16}>
                  <RichTextEditor
                    richTextMarkdown={transcription}
                    readOnly={true}
                  />
                </Box>
              </Grid>
              {
                charactersBreakdown &&
                <Grid item xs={12} className={classNames(classes.modalGridItems)}>
                  {this.renderTranscriptionBreakdownTable(charactersBreakdown)}
                </Grid>
              }
            </Grid>
          </div>
        </ItemModal>
      </div>
    )
  }

  renderLanguageIcon(countryCode) {
    const {classes} = this.props;
    if (!countryCode) {
      return null;
    }
    return (
      <div className={classes.blockContent}>
        <div className={classes.floatRight}>
          <LanguageSelector lng={ countryCode } />
        </div>
      </div>
      );
  }

  updatePinCords = ({lat, lng}) => {
    this.setState({pinLatLon: [lat, lng]});
  };

  formatDate(date) {
    if (!date) {
      return null;
    }
    return (
      <Moment format='D MMM YYYY'>{date}</Moment>
    )
  }

  renderItemDetailsTable(item) {
    const {classes, t} = this.props;
    let {
      courseCurriculum,
      createdAt,
      upLoadedAt,
      source,
      licence,
      maximumAge,
      minimumAge,
      language,
      totalNumberOfChars,
      transcription,
      transcriptionVisibility,
      charactersBreakdown
    } = item;
    let targetAge = 'Everyone';
    let transcriptionCharacterCount = totalNumberOfChars;

    if (!transcriptionCharacterCount) {
      transcriptionCharacterCount = 0;
    }

    if (!courseCurriculum) {
      courseCurriculum = []
    }

    if (maximumAge && minimumAge && minimumAge < 18) {
      targetAge = `${minimumAge}-${maximumAge}`;
    }

    if (maximumAge && minimumAge && minimumAge >= 18) {
      targetAge = '18+';
    }

    if (!maximumAge && minimumAge) {
      targetAge = minimumAge === 18 ? '18+' : 'Everyone';
    }

    const rowData = [
      {name: `${t('item.table.row.course_curriculum')}`, data: courseCurriculum.length ? courseCurriculum.join(', ') : ' - '},
      {name: `${t('item.table.row.created_date')}`, data: createdAt ? this.formatDate(createdAt): ''},
      {name: `${t('item.table.row.recording_date')}`, data: upLoadedAt ? this.formatDate(upLoadedAt) : ''},
      {name: `${t('item.table.row.source')}`, data: source},
      {name: `${t('item.table.row.licence')}`, data: licence},
      {name: `${t('item.table.row.target_age')}`, data: targetAge},
      {name: `${t('item.table.row.character_count')}`, data: transcriptionCharacterCount},
      {name: `${t('item.table.row.language')}`, data: this.renderLanguageIcon(language)},
      {name: `${t('item.table.row.transcript')}`, data: this.showTranscriptionModal({transcription: transcription, charactersBreakdown: charactersBreakdown})},
      {name: `${t('item.table.row.transcript_status')}`, data: transcriptionVisibility}
    ];

    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            {rowData.map(row => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.data}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  renderMap(item) {
    const {pinLatLon, latLon} = item;
    let initialPinLat;
    let initialPinLng;

    if (pinLatLon && pinLatLon.length) {
      initialPinLng = pinLatLon[0];
      initialPinLat = pinLatLon[1];
    } else if (latLon && latLon.length) {
      initialPinLng = latLon[0];
      initialPinLat = latLon[1];
    } else {
      return null;
    }

    return (
      <LayoutItemMap
        setPinCords={this.updatePinCords}
        isMarkerShown={true}
        initialPinLat={initialPinLat}
        initialPinLng={initialPinLng}
        initialZoom={4}
      />
    );
  }

  toggleMap = () => {
    const {mapChecked} = this.state;
    this.setState({mapChecked: !mapChecked});
  }

  render() {
    const {classes, t} = this.props;
    const {item, mapChecked} = this.state;
    const {owner_username, title, description, tags, owner} = item;


    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.gridItemsLeft}>
              <Typography >{`${t('item.uploaded_by')}: ${owner_username ? owner_username : owner}`}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.mediaCard}>
              <MyCardMedia {...item} />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.gridItemsLeft}>
              <Typography variant='h6'>{title ? title : `${t('item.missing_title')}`}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.gridItemsLeft}>
              <Typography variant='body2'>{description ? description : `${t('item.missing_description')}`}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.gridItemsLeft}>
              {tags && this.renderTags(tags)}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.gridItemsLeft}>
              {item && this.renderItemDetailsTable(item)}
            </div>
          </Grid>
          {(item.latLon || item.pinLatLon) &&
          <Grid item xs={12} className={classes.gridItem}>
            <FormControlLabel
              control={<Switch checked={mapChecked} onChange={this.toggleMap} />}
              label={t('item.show_map')}
              className={classes.showMapItem}
            />
            <Slide direction="up" in={mapChecked} mountOnEnter unmountOnExit>
              <div className={classNames(classes.gridItemsLeft, classes.mapContainer)}>
                <div className={classNames(classes.mapOverlay)}></div>
                {this.renderMap(item)}
              </div>
            </Slide>
          </Grid>
          }
        </Grid>
      </div>
    );
  }
}

Item.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(Item)));
