import React from 'react';
import API from '../../api/appsync';
import Upload from './upload';
import Progress from './progress';
import { withStyles } from '@material-ui/core';

const style = theme => ({
    previewRoot: {
        width: '100%',
        padding: theme.spacing()
    },
    previewImage: {
        objectFit: 'cover',
        width: '100%'
    },
    progressRoot: {
        textAlign: 'center',
        marginLeft: -80
    }
})

class CoverImage extends React.Component {

    state = {
        uploading: false,
        progress: 0,
        url: null
    }

    onPick = async data => {
        const {onDone, onUploadStart} = this.props;
        this.setState({uploading: true, progress: 0});
        if(onUploadStart) {
            onUploadStart();
        }
        const progressCallback = progress => {
            this.setState({
                progress: progress.loaded/progress.total
            });
        }
        const body = await API.uploadFile({progressCallback, data});
        if(onDone) {
            onDone(body.url);
        }

        let img = new Image();
        img.onload = _ => this.setState({url: body.url, uploading: false});
        img.src = body.url;
    }

    render() {
        const {classes} = this.props;
        const {uploading, progress, url} = this.state;
        if(uploading) {
            return <div className={classes.progressRoot}>
                <Progress progress={progress/100}/>
            </div>
        }
        if(url !== null) {
            return <div className={classes.previewRoot}>
                <img className={classes.previewImage} src={url} alt='Cover'/>
            </div>
        }
        return (
            <Upload
                onPick={this.onPick}
                accept="image/*;capture=camcorder"
                text="Drag-and-drop or click to select."
                title="Cover Image"
            />
        )
    }
}

export default withStyles(style)(CoverImage);