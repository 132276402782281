import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  root: {
    maxWidth: 460,
    minWidth: 150,
    width: '100%',
    cursor: 'pointer',
    marginTop: theme.spacing(1)
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    color: '#152939'
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 4,
    marginBottom: 24,
    color: '#828282'
  }
});

class LinkInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: '',
      error: false,
      helperText: ''
    }
  }

  onChange = (evt) => {
    const value = evt.target.value;
    this.setState({link: value});
    this.props.onChange(value);
  }

  render() {
    const {link, helperText, error} = this.state;
    const {classes, t} = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.title}>{t('upload.link.link')}</div>
        <div className={classes.text}>{t('upload.or_link')}</div>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                http://
              </InputAdornment>
            ),
          }}
          variant="outlined"
          fullWidth
          value={link}
          margin="normal"
          error= {error}
          helperText={helperText}
          onChange={this.onChange}
          label={t('upload.link.url_link')} />
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(LinkInput));
