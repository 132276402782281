import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import red from '@material-ui/core/colors/red';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Slider from '@material-ui/core/Slider';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import AudiotrackOutlinedIcon from '@material-ui/icons/AudiotrackOutlined';
import LinkIcon from '@material-ui/icons/Link';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import LanguageSelector from './../languageIcon/index'
import CoverImage from '../upload/coverImage';
import MyCardMedia from './media';
import RichTextEditor from './../textEditor/writeText'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    maxWidth: 400,
    maxHeight: 2000,
    margin: ' 0 auto'
  },
  action: {
    alignSelf: 'center',
    marginTop: '6px',
    marginRight: '0',
    "& div": {
      display: 'flex',
      alignItems: 'center',
    },
  },
  ownerLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  avatar: {
    backgroundColor: red[500],
    cursor: 'pointer'
  },
  chip: {
    margin: theme.spacing(),
    marginLeft: 0,
  },
  credits: {
    fontSize: '0.6em',
    margin: 0,
    marginTop: -10,
    color: '#bbb'
  },
  expansion: {
    padding: 8
  },
  viewItemContainer: {
    textAlign: 'center',
    margin: 'auto auto'
  },
  viewItemButton: {
    backgroundColor: theme.palette.primary
  },
  displayContent: {
    display: 'inline-block',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
  },
  titleContent: {
    marginRight: theme.spacing(8)
  },
  textField: {},
  ageRange: {
    width: '100%',
    maxWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  radioGroup: {
    display: 'inline-table',
    padding: '0px'
  },
  selectAlignLeft: {
    width: '100%',
    maxWidth: 500
  },
  selectFormControl: {
    minWidth: '100%'
  },
  blockContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  blockContentItemLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  blockContentLanguage: {
    display: 'flex',
    alignItems: 'center',
  },
  totalCharacterCount: {
    marginLeft: theme.spacing(2),
  },
  viewButtonRoot: {
    display: 'block'
  },
  blockContentView: {
    marginLeft: 'auto',
  },
});

const APPROVED = 'approved';

class TermResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      newTag: '',
      newCourse: '',
      tags: null,
      transcription: null,
      updating: false,
      coverImage: null,
      status: props.status,
      title: null,
      description: null,
      licence: null,
      minimumAge: null,
      maximumAge: null,
      transcriptionVisibility: null,
      courseCurriculum: null,
      ageSliderValue: [3, 18],
      language: props.language,
      body: props.body
    };
  }

  delete = _ => this.props.deleteItem(this.props.id);

  deleteTag(tag) {
    let {tags} = this.state;
    var index = tags.indexOf(tag);
    if (index > -1) {
      tags.splice(index, 1);
    }
    this.setState({tags});
  }

  deleteCourseCurriculumTag(tag) {
    let {courseCurriculum} = this.state;
    var index = courseCurriculum.indexOf(tag);
    if (index > -1) {
      courseCurriculum.splice(index, 1);
    }
    this.setState({courseCurriculum});
  }

  startEdit = _ => {
    const {
      tags,
      transcription,
      credit,
      coverImage,
      status,
      title,
      description,
      licence,
      minimumAge,
      maximumAge,
      transcriptionVisibility,
      courseCurriculum,
      language
    } = this.props;

    const {ageSliderValue} = this.state;

    if (minimumAge) {
      ageSliderValue[0] = minimumAge;
    }

    if (maximumAge) {
      ageSliderValue[1] = maximumAge;
    }

    this.setState({
      edit: true,
      tags: tags || [],
      courseCurriculum: courseCurriculum || [],
      title: title || '',
      description: description || '',
      licence: licence || '',
      minimumAge: minimumAge || 3,
      maximumAge: maximumAge || 18,
      ageSliderValue: ageSliderValue,
      transcriptionVisibility: transcriptionVisibility || 'public',
      transcription: transcription || '',
      credit: credit || '',
      coverImage: coverImage || null,
      status: status,
      language: language || ''
    })
  };

  update = async _ => {
    let {
      tags,
      transcription,
      credit,
      coverImage,
      status,
      title,
      description,
      licence,
      minimumAge,
      maximumAge,
      transcriptionVisibility,
      courseCurriculum,
      language
    } = this.state;

    const {id, updateItem} = this.props;

    if (transcription === '') {
      transcription = null;
    }

    if (credit === '') {
      credit = null;
    }

    if (language === '') {
      language = null;
    }

    this.setState({updating: true});
    await updateItem({
      id,
      tags,
      transcription,
      credit,
      coverImage,
      status,
      title,
      description,
      licence,
      minimumAge,
      maximumAge,
      transcriptionVisibility,
      courseCurriculum,
      language
    });
    this.setState({updating: false, edit: false, status});
  };

  approveItem = async _ => {
    const status = APPROVED;
    const {status: currentStatus} = this.state;
    const {id, updateItem} = this.props;

    if (currentStatus !== APPROVED) {
      this.setState({updating: true});
      await updateItem({id, status});
      this.setState({updating: false, edit: false, status});
    }
  };

  renderTags() {
    const {classes, t} = this.props;
    const {edit} = this.state;

    if (edit === false) {
      const {tags} = this.props;
      return tags.map((tag, index) => (
        <Chip
          key={`tag-id-${index}-${tag}`}
          label={tag}
          className={classes.chip}
          onClick={evt => this.props.history.push(`/search/${tag}`)}
        />
      ));
    }

    let {tags, newTag, updating} = this.state;

    return (
      <>
        <TextField
          label={t('term_result.add_tag')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={newTag}
          disabled={updating}
          fullWidth
          onChange={event => this.setState({newTag: event.target.value})}
          onKeyDown={event => {
            if(event.keyCode === 13) {
              tags.push(newTag);
              this.setState({tags, newTag: ''});
            }
          }}
        />
        {tags.map((tag, index) => (
          <Chip
            key={`tag-id-${index}-${tag}`}
            label={tag}
            onDelete={this.deleteTag.bind(this, tag)}
            className={classes.chip}
          />
        ))}
      </>
    )
  }

  selectIconMediaType(type) {
    let icon = null;

    switch (type) {
      case 'image':
        icon = (
          <CameraAltIcon key='image-media-type-icon' color='secondary'/>
        );
        break;
      case 'audio':
        icon = (
          <AudiotrackOutlinedIcon key='audio-media-type-icon' color='secondary'/>
        );
        break;
      case 'video':
        icon = (
          <VideocamOutlinedIcon key='video-media-type-icon' color='secondary'/>
        );
        break;
      case 'link':
        icon = (
          <LinkIcon key='link-media-type-icon' color='secondary'/>
        );
        break;
      case 'text':
        icon = (
          <ImportContactsIcon key='text-media-type-icon' color='secondary'/>
        );
        break;
      case 'youtube':
        icon = (
          <VideoLibraryIcon key='youtube-media-type-icon' color='secondary'/>
        );
        break;
      default:
        icon = null;
        break;
    }

    return icon
  }

  renderEdit() {
    const {isAdmin, isCurator, id, classes, status, type, t} = this.props;
    const {edit} = this.state;

    const canEdit = isAdmin || isCurator;
    const mediaIconType = this.selectIconMediaType(type);

    if (edit || !canEdit) {
      return [mediaIconType];
    };

    const editItemButton = (
      <Tooltip title={t('term_result.tool_tip.edit')} key={`${id}-edit-btn`}>
        <IconButton onClick={_ => this.startEdit()}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
    const deleteItemButton = (
      <Tooltip title={t('term_result.tool_tip.delete')} key={`${id}-delete-btn`}>
        <IconButton onClick={_ => this.delete()}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
    const isDisable = status === APPROVED;
    const approveButton = (
      <Tooltip title={t('term_result.tool_tip.approve')} key={`${id}-approve-btn`}>
        <div className={classes.displayContent}>
          <IconButton onClick={_ => this.approveItem()} disabled={isDisable}>
            <CheckIcon />
          </IconButton>
        </div>
      </Tooltip>
    );

    return [editItemButton, deleteItemButton, approveButton, mediaIconType];
  }

  renderTrans() {
    const {classes, owner, username, isAdmin, type, t} = this.props;
    const {edit, updating} = this.state;

    if (edit) {
      const {transcription} = this.state;
      return (
        <RichTextEditor
          label={t('term_result.transcription')}
          readOnly={updating}
          richTextMarkdown={transcription}
          onChange={({markdown}) => {
            this.setState({transcription: markdown})
          }}
        />
      )
    }
    const {transcription} = this.props;
    const canView = isAdmin || username === owner;

    if (!transcription || !canView || type === 'text') return null;

    return (
      <ExpansionPanel elevation={0}>
        <ExpansionPanelSummary className={classes.expansion} expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{t('term_result.transcription')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <RichTextEditor
            label={t('term_result.transcription')}
            readOnly={updating}
            richTextMarkdown={transcription}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderCredits() {
    const {classes, updating, type, t} = this.props;
    const {edit} = this.state;
    const {credit} = this.props;

    if (!credit) return null;

    if (edit && type !== 'link') {
      const {credit} = this.state;
      return (
        <TextField
          label={t('term_result.credit')}
          fullWidth
          multiline
          disabled={updating}
          value={credit}
          onChange={event => this.setState({credit: event.target.value})}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />
      )
    }

    return (<Typography variant='body2' className={classes.titleContent} gutterBottom>{credit}</Typography>)
  }

  renderChangeCover = _ => {
    const {edit} = this.state;
    const {type} = this.props;

    if (!edit) return null;

    if (type !== 'audio' && type !=='text') return null;

    return <CoverImage
      onDone={coverImage => this.setState({coverImage})}
    />;
  };

  renderActions() {
    const {t} = this.props;
    const {edit, updating} = this.state;

    if (!edit) return null;

    return (
      <CardActions>
        <Button
          disabled={updating}
          size="small"
          color="secondary"
          onClick={this.update}
          variant="contained">{t('term_result.button.save')}</Button>
        <Button
          disabled={updating}
          size="small"
          variant="outlined"
          onClick={_ => this.setState({edit: false})}>{t('term_result.button.cancel')}</Button>
      </CardActions>
    )
  }

  renderCourseCurriculum() {
    const {classes, t} = this.props;
    const {courseCurriculum, newCourse, updating} = this.state;
    return (
      <>
        <TextField
          label={t('term_result.add_course_curriculum')}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={newCourse}
          disabled={updating}
          fullWidth
          onChange={event => this.setState({newCourse: event.target.value})}
          onKeyDown={event => {
            if(event.keyCode === 13) {
              courseCurriculum.push(newCourse);
              this.setState({courseCurriculum, newCourse: ''});
            }
          }}
        />
        {courseCurriculum.map((tag, index) => (
          <Chip
            key={`tag-id-${index}-${tag}`}
            label={tag}
            onDelete={this.deleteCourseCurriculumTag.bind(this, tag)}
            className={classes.chip}
          />
        ))}
      </>
    )
  }

  renderTitleAndDescription() {
    const {classes, t} = this.props;
    const {edit} = this.state;

    if (!edit) {
      const {title, description} = this.props;
      return (
        <>
          <Typography variant='subtitle1' className={classes.title}>{title}</Typography>
          <Typography variant='body2' className={classes.titleContent} gutterBottom>{description}</Typography>
        </>
      )
    }

    let {title, description, licence, updating} = this.state;

    return (
      <>
        <TextField
          id="outlined-multiline-flexible-title"
          label={t('term_result.title')}
          fullWidth
          multiline
          value={title}
          disabled={updating}
          onChange={event => this.setState({title: event.target.value})}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="outlined-multiline-flexible-description"
          label={t('term_result.description')}
          fullWidth
          multiline
          value={description}
          disabled={updating}
          onChange={event => this.setState({description: event.target.value})}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="outlined-multiline-flexible-licence"
          label={t('term_result.licence')}
          fullWidth
          multiline
          value={licence}
          disabled={updating}
          onChange={event => this.setState({licence: event.target.value})}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />
      </>
    )
  }

  toggleAgeRangeSelect = async (event, newValue) => {
    this.setState({minimumAge: newValue[0], maximumAge: newValue[1], ageSliderValue: newValue});
  };

  renderAgeRange() {
    const {classes, t} = this.props;
    const {ageSliderValue} = this.state;

    return (
      <div className={classes.ageRange}>
        <Typography id="range-slider" gutterBottom>
          {t('term_result.age_range')}
        </Typography>
        <Slider
          value={ageSliderValue}
          onChange={this.toggleAgeRangeSelect}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={(value) => `${value}`}
        />
      </div>
    )
  }

  setTranscriptionVisibility = (event) => {
    this.setState({transcriptionVisibility: event.target.value});
  };

  renderTranscriptionVisibility() {
    const {classes, t} = this.props;
    const {transcriptionVisibility} = this.state;

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup className={classes.radioGroup} name="transcriptionVisibility" value={transcriptionVisibility} onChange={this.setTranscriptionVisibility}>
          <FormControlLabel value="private" control={<Radio color='secondary'/>} label={t('term_result.radio.private_transcription')} />
          <FormControlLabel value="public" control={<Radio color='secondary'/>} label={t('term_result.radio.public_transcription')} />
        </RadioGroup>
      </FormControl>
    )
  }

  renderLanguageIcon(countryCode) {
    return (<LanguageSelector lng={ countryCode } />)
  }

  renderLanguage() {
    const {classes, t} = this.props;
    const {language} = this.state;

    return (
      <FormControl variant="outlined" className={classes.selectFormControl}>
        <FormLabel component="legend" id="select-label-language" className={classes.selectAlignLeft}>{t('term_result.language')}</FormLabel>
        <Select
          id="select-language"
          className={classes.selectAlignLeft}
          value={language}
          onChange={event => this.setState({language: event.target.value})}
        >
          <MenuItem value='GB'>{t('term_result.menu_options.english')}</MenuItem>
          <MenuItem value='CN'>{t('term_result.menu_options.chinese')}</MenuItem>
        </Select>
      </FormControl>
    )
  }

  renderTotalCharacterCount() {
    const {totalNumberOfChars, language} = this.props;

    if (language === 'GB') {
      return null;
    }

    return (<Typography variant='body2'>{totalNumberOfChars}</Typography>)
  }

  formLink(body) {
    const {url, youtubeId} = body;

    if (youtubeId) {
      return `https://youtu.be/${youtubeId}`;
    }

    return url;
  }

  render() {
    const {edit} = this.state;
    const { classes, owner, id, owner_username, owner_picture, t, type } = this.props;
    const { language, body } = this.state;
    const itemContentLink = this.formLink(body);

    return (
    <Card className={classes.card} key={id}>
      <CardHeader
          classes={{ action: classes.action }}
          avatar={
            <Avatar
              onClick={evt => this.props.history.push(`/profile/${owner}`)}
              aria-label="Recipe"
              className={classes.avatar}
              src={owner_picture}
            />
          }
          action={<div>{this.renderEdit()}</div>}
          title={<Link to={`/profile/${owner}`} className={classes.ownerLink}>{owner_username || owner}</Link>}
      />
      <MyCardMedia {...this.props} />
      <CardContent style={{marginBottom: 0}}>
        {this.renderChangeCover()}
        {
          type !== 'text'
          ? <Grid item xs={12}>
              <div className={classes.blockContentItemLink}>
                <div className={ classes.blockContentView }>
                  <a href={itemContentLink} rel="noopener noreferrer" aria-label={t('term_result.tool_tip.view_item')}>
                    <OpenInNewIcon color='primary'/>
                  </a>
                </div>
              </div>
            </Grid>
          : null
        }
        <Grid container>
          {edit &&
            <Grid item xs={edit ? 12 : 10}>
              {this.renderTrans()}
            </Grid>
          }
          {edit &&
            <Grid item xs={edit ? 12 : 10}>
              {this.renderTranscriptionVisibility()}
            </Grid>
          }
          <Grid>
            {this.renderTitleAndDescription()}
          </Grid>
          <Grid item xs={edit ? 12 : 10}>
            {this.renderCredits()}
          </Grid>
          <Grid item xs={edit ? 12 : 10}>
            {this.renderTags()}
          </Grid>
          {!edit &&
          <Grid item xs={12}>
            <div className={classes.blockContent}>
              {language && <div className={ classes.blockContentLanguage }>
                <div>
                  {this.renderLanguageIcon(language)}
                </div>
                <div className={ classes.totalCharacterCount }>
                  {this.renderTotalCharacterCount()}
                </div>
              </div>}
              <div className={ classes.blockContentView }>
                <Tooltip title={t('term_result.tool_tip.view_item')} key={`${id}-view-btn`}>
                  <Link to={`/item/view/${id}`} classes={{root: classes.viewButtonRoot}} aria-label={t('term_result.tool_tip.view_item')}>
                    <VisibilityIcon color='primary'/>
                  </Link>
                </Tooltip>
              </div>
            </div>
          </Grid>
          }
          {edit &&
            <Grid item xs={edit ? 12 : 10}>
              {this.renderLanguage()}
            </Grid>
          }
          {edit &&
            <Grid item xs={edit ? 12 : 10}>
              {this.renderCourseCurriculum()}
            </Grid>
          }
          {edit &&
            <Grid item xs={edit ? 12 : 10}>
              {this.renderAgeRange()}
            </Grid>
          }
        </Grid>
      </CardContent>
      {this.renderActions()}
    </Card>
    );
  }
}

TermResult.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(TermResult)));
