import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {isEmpty} from 'lodash';
import { withTranslation } from 'react-i18next'

import API from '../../api/appsync';
import TermResult from '../termResult';
import FederatedItemCard from '../federatedItemCard'

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    button: {
        margin: theme.spacing(),
        height: '30px'
    },
    breadcrumbsRoot: {
        marginLeft: theme.spacing(6),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(14),
        },
    },
    badgePadding: {
        padding: `0 ${theme.spacing(2)}px`,
    },
    nextButton: {
        margin: theme.spacing(1),
        flex: 1,
        float: "right"
    }
});

class TermResults extends React.Component {

    state = {
        username: '',
        loadingMore: false,
        selectedItemKey: '',
        selectedItem: {},
        loadingFederatedItem: true
    };

    async componentWillMount() {
        const {userName} = await API.getUsername();
        this.setState({username: userName});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedItem} = this.state;
        if (prevState.selectedItem !== selectedItem) {
            if (!isEmpty(selectedItem)) {
                this.setState({loadingFederatedItem: false});
            }
        }
    }

    handleGetMore = async _ => {
        const {getMoreItems} = this.props;
        this.setState({loadingMore: true});
        await getMoreItems(10);
        this.setState({loadingMore: false});
    };

    toggleSelectedFederatedItem = (key, item) => {
        this.setState({selectedItemKey: key, selectedItem: item});
        this.props.setSelected(item);
    };

    createFederatedItem = async _ => {
      const {selectedItem} = this.state;
      const {createFederatedItem: parentCreateFederatedItem} = this.props;
      let suggestedTags = null;
      this.setState({loadingFederatedItem: true});

      if (!selectedItem) {
          return false;
      }

      if (selectedItem.type === 'image') {
          suggestedTags = await API.getSuggestedTags({imageUrl: selectedItem.url});
      }

      parentCreateFederatedItem(selectedItem, suggestedTags);
    };

    updateItem = async item => this.props.updateItem(item);
    deleteItem = async id => this.props.deleteItem(id);

    showCorrectStatus(status) {
        const {t} = this.props;
        let correctStatus = '';

        switch (status) {
            case 'approved':
                correctStatus = t('term_results.breadcrumbs.approved');
                break;
            case 'transcription_pending':
                correctStatus = t('term_results.breadcrumbs.transcription_pending');
                break;
            case 'pending_approval':
                correctStatus = t('term_results.breadcrumbs.pending_approval');
                break;
            default:
                correctStatus = '-';
                break;
        }

        return correctStatus;
    }

    showCorrectType(type) {
        const {t} = this.props;
        let correctType = '';

        switch (type) {
            case 'image':
                correctType = t('term_results.breadcrumbs.image');
                break;
            case 'audio':
                correctType = t('term_results.breadcrumbs.audio');
                break;
            case 'video':
                correctType = t('term_results.breadcrumbs.video');
                break;
            case 'text':
                correctType = t('term_results.breadcrumbs.text');
                break;
            case 'link':
                correctType = t('term_results.breadcrumbs.link');
                break;
            case 'youtube':
                correctType = t('term_results.breadcrumbs.youtube');
                break;
            default:
                correctType = '-';
                break;
        }

        return correctType;
    }

    constructBreadcrumb(status, type) {
        const {t} = this.props;
        const breadcrumbs = [];

        if (!status) {
            breadcrumbs.push(<Typography key='brd-all-statuses' color="primary">{t('term_results.breadcrumbs.all_statuses')}</Typography>)
        } else {
            breadcrumbs.push(<Typography key={`brd-${status}`} color="primary">{this.showCorrectStatus(status)}</Typography>)
        }

        if (type) {
            breadcrumbs.push(<Typography key={`brd-${type}`} color="primary">{this.showCorrectType(type)}</Typography>)
        }

        return breadcrumbs;
    }

    render() {
        let {filter: {status, type}, items, classes, getMoreItems, isAdmin, isCurator, isTeacher, isFederatedSearch, t} = this.props;
        const {username, loadingMore, loadingFederatedItem} = this.state;

        return (
            <>
            {!isFederatedSearch && <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsRoot}>
                {this.constructBreadcrumb(status, type)}
            </Breadcrumbs>}
            {isFederatedSearch && <Button className={classes.nextButton} color="secondary" disabled={loadingFederatedItem} onClick={this.createFederatedItem}>Create Item ></Button>}
            <Grid container className={classes.root} spacing={8} justify="center">
                {isFederatedSearch && items.map((item, index) => (
                    <Grid item xs={8} md={4} key={`${index}-${item.type}`}>
                        <FederatedItemCard
                          {...item}
                          itemKey={`${index}-${item.type}`}
                          selectItem={this.toggleSelectedFederatedItem}
                          selectedItemKey={this.state.selectedItemKey}
                          isAdmin={isAdmin}
                          isCurator={isCurator}
                          isTeacher={isTeacher}/>
                    </Grid>
                ))}
                {!isFederatedSearch && items.map((item, index) => (
                    <Grid item xs={12} md={6} key={`${index}-${item.id}`}>
                        <TermResult
                            {...item}
                            updateItem={this.updateItem}
                            deleteItem={this.deleteItem}
                            loc={item.location}
                            isAdmin={isAdmin}
                            isCurator={isCurator}
                            isTeacher={isTeacher}
                            username={username}/>
                    </Grid>
                ))}
                {getMoreItems &&
                    <Grid item xs={12} key='moreButton' align='center'>
                        <Fab
                          disabled={loadingMore}
                          variant="extended"
                          className={classes.button}
                          color='primary'
                          onClick={this.handleGetMore}>
                            {t('term_results.button.load_more')}
                        </Fab>
                    </Grid>
                }
            </Grid>
            </>
        )
    }
};

const mapStateToProps = ({isAdmin, isCurator, isTeacher}) => ({isAdmin, isCurator, isTeacher});
export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(TermResults)));
