import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardMedia from '@material-ui/core/CardMedia';


const ImageLoading = props => (
    <div>
      <ImageIcon style={{
        width: '100%',
        height: 200,
        color: '#eee'
      }}/>
      <LinearProgress />
    </div>
)

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    }
};

class MediaImage extends React.Component {

    state = {
        imageUrl: null
    }

    async componentWillMount() {
        const { body } = this.props;
        const imageUrl = body.thumbnail || body.url;
        const img = new Image();
        img.src = imageUrl;
        img.onload = _ => {
          this.setState({imageUrl});
        }
    }

    render() {
        const {tags, classes} = this.props;
        const {imageUrl} = this.state;
        if(imageUrl === null) {
            return <ImageLoading/>
        }
        return (
            <a href={this.props.body.url}>
            <CardMedia
                className={classes.media}
                image={imageUrl}
                title={`${tags.join(',')}`}
                style={{height: 250, backgroundSize: 'contain'}}
            />
            </a>
        )
    }
}

export default withStyles(styles)(MediaImage);
