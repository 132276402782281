import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import {fade} from '@material-ui/core/styles/colorManipulator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    },
  },
  paperRoot: {
    display: 'contents',
    padding: '2px 4px',
    alignItems: 'center'
  },
  input: {
    border: 'solid 1px',
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: fade(theme.palette.common.white, 0.9),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.9),
    },
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(4),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.shape.borderRadius,
    },
  },
  iconButton: {
    padding: 10,
    marginLeft: 10,
  },
  button: {
    padding: 10,
    paddingLeft: 25,
    paddingRight: 25,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    minWidth: 85,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
}));

export default function CustomizedFederatedInputBase({onChange, onKeyDown, value, onSearchPress}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoot}>
        <InputBase
          className={classes.input}
          placeholder={t('federated_search.input.search_for_media_online')}
          inputProps={{ 'aria-label': 'Search...' }}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value || ''}
        />
        <IconButton className={`${classes.iconButton} ${classes.sectionMobile}`} aria-label="Search" onClick={onSearchPress}>
          <SearchIcon />
        </IconButton>
        <div className={classes.sectionDesktop}>
          <Button
            variant='contained'
            onClick={onSearchPress}
            className={classes.button}
            color='secondary'
          >
            {t('federated_search.button.search')}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
