import React from 'react';
import {Redirect} from 'react-router-dom';
import { withTranslation } from 'react-i18next'

import ContainerSearch from '../../containers/search';
// import Upgrade from '../../components/upgradeUI';
import API from '../../api/appsync';
import FilterDrawer from '../../components/filterDrawer';
import {withStyles} from '@material-ui/styles';
import TermResults from '../../components/termResults';
import {Typography} from '@material-ui/core';

const styles = theme => ({
    resultContainer: {
        [theme.breakpoints.up('sm')]: {}
    },
    noResult: {
        marginLeft: 40,
        marginTop: 40
    },
    filterContainer: {
        display: 'block'
    }
});

class PageProfile extends React.Component {

    state = {
        profile: null,
        redirect: false,
        filter: {},
        locations: null
    }

    async componentDidMount() {
        // userId is the cognito sub value
        const {userId} = this.props.match.params;
        const locations = await API.getLocations();
        this.setState({locations});
        if (userId !== 'me') {
            this.setState({profile: userId, filter: {owner: userId}});
        } else {
            const {userId: _userId} = await API.getUsername();
            this.setState({profile: _userId, redirect: true, filter: {owner: _userId}});
        }
    }

    render() {
        const {profile, redirect, filter, locations} = this.state;
        const {classes, t} = this.props;
        const {username} = this.props.match.params;

        if (profile === null) return null;

        if (redirect && username === 'me') return <Redirect to={`/profile/${profile}`}/>;

        return (
            <React.Fragment>
                {/* <Upgrade /> */}
                <div className={classes.filterContainer}>
                    <FilterDrawer
                      locations={locations}
                      filter={filter}
                      setFilter={filter => this.setState({filter})}
                    />
                </div>
                <ContainerSearch filter={filter}>
                    {myProps => {
                        if(myProps.items.length > 0) {
                            return <TermResults {...myProps}/>
                        }
                        return (
                          <div className={classes.noResult}>
                              <Typography>{t('no_results_found_msg')}</Typography>
                          </div>
                        );
                    }}
                </ContainerSearch>
            </React.Fragment>
        )
    }
};

export default withStyles(styles)(withTranslation()(PageProfile));
