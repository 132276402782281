import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputLabel from '@material-ui/core/InputLabel';
import {DatePicker} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

import AutoSelect from '../autoSelect';
import UserSearchDropDown from './../userSearch';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  openBtn: {
    position: 'fixed',
    marginTop: -10,
    left: theme.spacing(3),
    zIndex: '1',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.secondary.main,
    }
  },
  toolbar: {
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    height: '30px'
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(1)}px`,
  }
}));

export default function ResponsiveDrawer(props) {
  const { t } = useTranslation();
  const { container, filter, locations } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [searchMenuOpen, toggleSearchMenu] = React.useState(false);
  const [position, setPosition] = React.useState(null);
  const [values, setValues] = React.useState({
    tags: filter.tags ? filter.tags[0] : '',
    courseCurriculum: filter.courseCurriculum ? filter.courseCurriculum[0] : '',
    owner: filter.owner || '',
    credit: filter.credit || '',
    transcription: filter.transcription || '',
    type: filter.type || '',
    status: filter.status || '',
    transcriptionType: filter.transcriptionType || '',
    transcriptionVisibility: filter.transcriptionVisibility || '',
    language: filter.language || '',
    source: filter.source || '',
    licence: filter.licence || '',
    ageRange: '',
    title: filter.title || '',
    description: filter.description || ''
  });

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(pos => setPosition(pos.coords));
  }

  function handleDrawerToggle() {
    toggleSearchMenu(!searchMenuOpen);
  }

  const handleClear = _ => {
    setValues({
      ...values,
      tags: '',
      courseCurriculum: '',
      source: '',
      licence: '',
      credit: '',
      transcription: '',
      type: '',
      city: null,
      status: ''
    })
  };

  const handleChange = name => event => {
    let newValues = null;
    switch(name) {
      case 'createdFrom':
      case 'createdTo':
      case 'upLoadedFrom':
      case 'upLoadedTo':
        newValues = { ...values, [name]: event ? event.format() : null};
        break;
      default:
        newValues = { ...values, [name]: event.target.value };
    }
    setValues(newValues);
  };

  function filterAgeRange(ageRange) {
    let maxAgeFilter = null;
    const minAgeFilter = {
      ge: ageRange[0]
    };

    if (ageRange.length > 1) {
      maxAgeFilter = {
        le: ageRange[1]
      }
    }

    return {maxAgeFilter, minAgeFilter};
  }

  function filterTotalNumberOfChars(minValue, maxValue) {
    let filterObj = {};

    if (minValue && maxValue) {
      filterObj.between = [minValue, maxValue]
    }
    if (minValue && !maxValue) {
      filterObj.ge = minValue
    }
    if (!minValue && maxValue) {
      filterObj.le = maxValue
    }

    return filterObj;
  }

  function search() {
    const {setFilter} = props;
    let newFilter = {};

    for (const key in values) {
      let ent = values[key];

      if (ent === '') {
        continue;
      }

      switch(key) {
        case 'distance':
          newFilter['latLon'] = {
            distance: ent,
            location: {
              lat: position.latitude,
              lon: position.longitude
            }
          };
          break;
        case 'createdFrom':
        case 'createdTo':
          const from = values.hasOwnProperty('createdFrom') ? moment(values['createdFrom']) : moment.unix(0);
          const to = values.hasOwnProperty('createdTo') ? moment(values['createdTo']) : moment();
          newFilter['createdAt'] = {
            between: [
              from.startOf('day'),
              to.endOf('day')
            ]
          };
          break;
        case 'minimumNumberOfChars':
        case 'maximumNumberOfChars':
          const minimumNumberOfChars = values['minimumNumberOfChars'];
          const maximumNumberOfChars = values['maximumNumberOfChars'];
          newFilter['totalNumberOfChars'] = filterTotalNumberOfChars(minimumNumberOfChars, maximumNumberOfChars);
          break;
        case 'upLoadedFrom':
        case 'upLoadedTo':
          const fromUploadedAt = values.hasOwnProperty('upLoadedFrom') ? moment(values['upLoadedFrom']).format('YYYY-MM-DD') : moment.unix(0).format('YYYY-MM-DD');
          const toUploadedAt = values.hasOwnProperty('upLoadedTo') ? moment(values['upLoadedTo']).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
          newFilter['upLoadedAt'] = {
            between: [
              fromUploadedAt,
              toUploadedAt
            ]
          };
          break;
        case 'credit':
        case 'transcription':
        case 'title':
        case 'description':
          newFilter[key] = {'contains': ent};
          break;
        case 'ageRange':
          const {maxAgeFilter, minAgeFilter} = filterAgeRange(values['ageRange'].split('-').map(v => Number(v)));

          newFilter['minimumAge'] = minAgeFilter;

          if (maxAgeFilter) {
            newFilter['maximumAge'] = maxAgeFilter;
          }

          break;
        default:
          newFilter[key] = ent;
      }
    }
    setFilter(newFilter);
    toggleSearchMenu(false);
  };

  const drawer = (
    <div className={classes.toolbar}>
      <List>
        <ListItem>
          <UserSearchDropDown
            setSearchedUser={(value) => {
              setValues({...values, 'owner': value});
            }}
          />
        </ListItem>
        <ListItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-simple">{t('filter_drawer.list.labels.type')}</InputLabel>
            <Select
              value={values.type || ''}
              onChange={handleChange('type')}
            >
              <MenuItem value=''><em>{t('filter_drawer.list.menu_options.type.none')}</em></MenuItem>
              <MenuItem value='image'>{t('filter_drawer.list.menu_options.type.image')}</MenuItem>
              <MenuItem value='video'>{t('filter_drawer.list.menu_options.type.video')}</MenuItem>
              <MenuItem value='audio'>{t('filter_drawer.list.menu_options.type.audio')}</MenuItem>
              <MenuItem value='text'>{t('filter_drawer.list.menu_options.type.text')}</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.title')}
            className={classes.textField}
            value={values.title}
            onChange={handleChange('title')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.description')}
            className={classes.textField}
            value={values.description}
            onChange={handleChange('description')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.source')}
            className={classes.textField}
            value={values.source}
            onChange={handleChange('source')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.credits')}
            className={classes.textField}
            value={values.credit}
            onChange={handleChange('credit')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.tag')}
            className={classes.textField}
            value={values.tags}
            onChange={handleChange('tags')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.course_curriculum')}
            className={classes.textField}
            value={values.courseCurriculum}
            onChange={handleChange('courseCurriculum')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <DatePicker
            label={t('filter_drawer.list.labels.uploaded_after')}
            value={values.upLoadedFrom || null}
            maxDate={new Date()}
            className={classes.textField}
            onChange={handleChange('upLoadedFrom')}
          />
        </ListItem>
        <ListItem>
          <DatePicker
            label={t('filter_drawer.list.labels.uploaded_before')}
            value={values.upLoadedTo || null}
            maxDate={new Date()}
            className={classes.textField}
            onChange={handleChange('upLoadedTo')}
          />
        </ListItem>
        <ListItem>
          <DatePicker
            label={t('filter_drawer.list.labels.created_after')}
            value={values.createdFrom || null}
            maxDate={new Date()}
            className={classes.textField}
            clearable
            onChange={handleChange('createdFrom')}
          />
        </ListItem>
        <ListItem>
          <DatePicker
            label={t('filter_drawer.list.labels.created_before')}
            value={values.createdTo || null}
            maxDate={new Date()}
            className={classes.textField}
            clearable
            onChange={handleChange('createdTo')}
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.licence')}
            className={classes.textField}
            value={values.licence}
            onChange={handleChange('licence')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-simple">{t('filter_drawer.list.labels.age_range')}</InputLabel>
            <Select
              value={values.ageRange || ''}
              onChange={handleChange('ageRange')}
            >
              <MenuItem value=''><em>{t('filter_drawer.list.menu_options.age_range.none')}</em></MenuItem>
              <MenuItem value='3'>{t('filter_drawer.list.menu_options.age_range.everyone')}</MenuItem>
              <MenuItem value='3-5'>{t('filter_drawer.list.menu_options.age_range.3_5')}</MenuItem>
              <MenuItem value='5-7'>{t('filter_drawer.list.menu_options.age_range.5_7')}</MenuItem>
              <MenuItem value='7-11'>{t('filter_drawer.list.menu_options.age_range.7_11')}</MenuItem>
              <MenuItem value='11-14'>{t('filter_drawer.list.menu_options.age_range.11_14')}</MenuItem>
              <MenuItem value='14-16'>{t('filter_drawer.list.menu_options.age_range.14_16')}</MenuItem>
              <MenuItem value='16-18'>{t('filter_drawer.list.menu_options.age_range.16_18')}</MenuItem>
              <MenuItem value='18'>{t('filter_drawer.list.menu_options.age_range.18')}</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.minimum_number_of_chars')}
            value={values.minimumNumberOfChars || ''}
            className={classes.textField}
            onChange={handleChange('minimumNumberOfChars')}
          />
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.maximum_number_of_chars')}
            value={values.maximumNumberOfChars || ''}
            className={classes.textField}
            onChange={handleChange('maximumNumberOfChars')}
          />
        </ListItem>
        <ListItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="transcription-language">{t('filter_drawer.list.labels.transcription_language')}</InputLabel>
            <Select
              value={values.language}
              onChange={handleChange('language')}
            >
              <MenuItem value=''><em>{t('filter_drawer.list.menu_options.transcription_language.none')}</em></MenuItem>
              <MenuItem value='GB'>{t('filter_drawer.list.menu_options.transcription_language.english')}</MenuItem>
              <MenuItem value='CN'>{t('filter_drawer.list.menu_options.transcription_language.chinese')}</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="transcription-visibility">{t('filter_drawer.list.labels.transcription_visibility')}</InputLabel>
            <Select
              value={values.transcriptionVisibility}
              onChange={handleChange('transcriptionVisibility')}
            >
              <MenuItem value=''><em>{t('filter_drawer.list.menu_options.transcription_visibility.none')}</em></MenuItem>
              <MenuItem value='public'>{t('filter_drawer.list.menu_options.transcription_visibility.public')}</MenuItem>
              <MenuItem value='private'>{t('filter_drawer.list.menu_options.transcription_visibility.private')}</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <TextField
            label={t('filter_drawer.list.labels.transcription')}
            className={classes.textField}
            value={values.transcription}
            onChange={handleChange('transcription')}
            margin="none"
          />
        </ListItem>
        <ListItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="transcription-type">{t('filter_drawer.list.labels.transcription_type')}</InputLabel>
            <Select
              value={values.transcriptionType}
              onChange={handleChange('transcriptionType')}
            >
              <MenuItem value=''><em>{t('filter_drawer.list.menu_options.transcription_type.none')}</em></MenuItem>
              <MenuItem value='auto'>{t('filter_drawer.list.menu_options.transcription_type.auto')}</MenuItem>
              <MenuItem value='manual'>{t('filter_drawer.list.menu_options.transcription_type.manual')}</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <AutoSelect
            suggestions={locations ? locations.map(suggestion => ({
              value: suggestion.label,
              label: suggestion.label,
            })) : {}}
            className={classes.textField}
            label={t('filter_drawer.list.labels.city')}
            value={values.city}
            onChange={evt => {
              let value = null;
              if (evt) {
                value = evt.value;
              }
              setValues({ ...values, 'city': value });
            }}
          />
        </ListItem>
        {/* <ListItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-simple">Distance</InputLabel>
            <Select
              value={values.distance || ''}
              onChange={handleChange('distance')}
              disabled={position === null}
            >
              <MenuItem value=''><em>Globally</em></MenuItem>
              <MenuItem value='20km'>20km</MenuItem>
              <MenuItem value='100km'>100km</MenuItem>
              <MenuItem value='500km'>500km</MenuItem>
            </Select>
          </FormControl>
        </ListItem> */}
        <ListItem>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-simple">{t('filter_drawer.list.labels.status')}</InputLabel>
            <Select
              value={values.status || ''}
              onChange={handleChange('status')}
            >
              <MenuItem value=''><em>{t('filter_drawer.list.menu_options.status.none')}</em></MenuItem>
              <MenuItem value='approved'>{t('filter_drawer.list.menu_options.status.approved')}</MenuItem>
              <MenuItem value='transcription_pending'>{t('filter_drawer.list.menu_options.status.transcription_pending')}</MenuItem>
              <MenuItem value='pending_approval'>{t('filter_drawer.list.menu_options.status.pending_approval')}</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
      </List>
      <Button variant="contained" className={classes.button} onClick={search} color='primary'>
        {t('filter_drawer.list.button.update')}
      </Button>
      <Button variant="contained" className={classes.button} onClick={handleClear} color='primary'>
        {t('filter_drawer.list.button.clear')}
      </Button>
    </div>
  );

  return (<>
      <div className={classes.openBtn}>
        <Tooltip title='Filter' key={`filter-btn`}>
          <Button
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            startIcon={<FilterListIcon />}
            className={classes.menuButton}
          >
            {t('term_results.filter')}
          </Button>
        </Tooltip>
      </div>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden xsUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={searchMenuOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}
