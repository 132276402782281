import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import RichTextEditor from './../textEditor/writeText'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '90%',
    marginTop: theme.spacing(10),
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none'
  },
  textArea: {
      width: '100%',
      height: 'calc(100vh - 300px)',
      resize: 'none',
      border: 'none',
      fontSize: 16
  }
}));

export default function Text({text, richTextMarkdown, richTextDraftJs, showPreview = false, previewLength = 120}) {
    const { t } = useTranslation();
    let preview = text;
    if (preview && preview.length > previewLength) {
        preview = `${preview.substring(0,previewLength - 3)}...`;
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const classes = useStyles();

    return (
        <div>
        {showPreview &&
            <Typography gutterBottom>{preview}</Typography>
        }
        <Button onClick={handleOpen}>{t('text.button.open_text')}</Button>
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
        >
            <div className={classes.paper}>
              <RichTextEditor
                text={text}
                richTextMarkdown={richTextMarkdown}
                richTextDraftJs={richTextDraftJs}
                readOnly={true}
              />
            </div>
        </Modal>
        </div>
    );
}
