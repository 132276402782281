import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MicIcon from '@material-ui/icons/Mic';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardMedia from '@material-ui/core/CardMedia';


const ImageLoading = props => (
    <div>
      <MicIcon style={{
        width: '100%',
        height: 200,
        color: '#eee'
      }}/>
      <LinearProgress />
    </div>
)

const styles = {
    root: {
        maxHeight: 250,
        minHeight: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

class Audio extends React.Component {

    state = {
        audioUrl: null
    }

    async componentWillMount() {
        const { body } = this.props;
        const audioUrl = body.url;
        this.setState({audioUrl});
    }

    render() {
        const {tags, classes, noHeight} = this.props;
        const {audioUrl} = this.state;
        if(audioUrl === null) {
            return <ImageLoading/>
        }
        let style={};
        if(noHeight) {
            style = {
                height: 'auto'
            }
        }
        return (
            <div className={classes.root} style={style}>
                <CardMedia
                    component='audio'
                    controls
                    src={audioUrl}
                    title={`${tags.join(',')}`}
                    style={{width: '90%'}}
                />
            </div>
        )
    }
}

export default withStyles(styles)(Audio);
