// Wrapper around the actual Stripe <*Element>, so that it can be used as `inputComponent`
// for Material UI's <Input>. Also adds some styling.

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  root: {
    width: '100%',
    padding: '6px 0 7px',
    cursor: 'text',
  }
})

export default withStyles(styles)(class extends PureComponent {
  static displayName = 'StripeInput'

  static propTypes = {
    classes: PropTypes.object.isRequired,
    // theme: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
  }

  render() {
    const {
      classes,
      // theme,
      component: Component,
      onFocus,
      onBlur,
      onChange,
      placeholder
    } = this.props

    return (
      <Component
        className={classes.root}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder || ''}
        // style={{
        //   base: {
        //     fontSize: `${theme.typography.fontSize}px`,
        //     fontFamily: theme.typography.fontFamily,
        //     color: '#000000de',
        //   },
        // }}
      />
    )
  }
})