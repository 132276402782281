import React from 'react';

import Image from './image';
import Youtube from './youtube';

export default ({url, source, isSelected}) => {
  let media = null;
  switch(source.toLowerCase()) {
    case 'google images':
      media = <Image url={url} isSelected={isSelected}/>;
      break;
    case 'youtube':
      media = <Youtube url={url}/>;
      break;
    default:
      return null;
  }

  return (
    <>
      {media}
    </>
  )
}
