import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import LinearProgress from '@material-ui/core/LinearProgress';

const ImageLoading = props => (
    <div>
      <ImageIcon style={{
        width: '100%',
        height: 200,
        color: '#eee'
      }}/>
      <LinearProgress />
    </div>
)

class MyImage extends React.Component {
    state = {
        url: null,
        loading: true
    }

    async componentWillMount() {
        const {url} = this.props.src;
        let img = new Image();

        img.onload = _ => this.setState({url, loading: false});
        img.src = url;
        this.setState({url});
        return;
    }

    render() {
        const {url, loading} = this.state;
        const {style, className} = this.props;
        if(loading) {
            return <ImageLoading />
        }
        return <img alt='Alt Text' style={style} className={className} src={url}/>
    }
}

export default MyImage