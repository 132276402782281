import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {withTranslation} from 'react-i18next'

import Preview from './uploadPreviewHelpers';
import RichTextEditor from './../textEditor/writeText'

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      padding: '35px 40px!important',
      maxWidth: 460,
      minWidth: 150,
      width: '100%',
      margin: '5% auto 50px',
      cursor: 'pointer'
    },
    button: {
      borderRadius: 0,
      boxShadow: 'none',
      fontSize: 12,
      fontWeight: 400,
      padding: '14px 0',
      marginTop: theme.spacing(1)
    },
    title: {
      fontSize: 18,
      fontWeight: 500,
      color: '#152939',
      marginBottom: theme.spacing(1)
    },
    text: {
        fontSize: 16,
        fontWeight: 400,
        marginTop: 4,
        marginBottom: 24,
        color: '#828282'
    }
  });

class TextUpload extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        text: '',
        rawJsContent: '',
        markdown: ''
      }
    }

    onChange = ({text, rawJsContent, markdown}) => {
      this.setState({
        text,
        rawJsContent,
        markdown
      });
    }


    submit = _ => {
        const {
          text,
          rawJsContent,
          markdown
        } = this.state;

        const {onDone} = this.props;
        // we save all three representations of the text so that we have a fallback for displaying simple text if rich text is not supported
        const body = {text, richTextMarkdown: markdown, richTextDraftJs: rawJsContent};

        onDone('text', {
            body,
            preview: Preview({type: 'text', text})
        });
    }

    render() {
        const {text} = this.state;
        const {classes, t} = this.props;

        return (
            <Paper className={classes.root} elevation={1}>
                <div className={classes.title}>{t('text.text')}</div>
                <RichTextEditor
                    onChange={this.onChange}
                    readOnly={false}
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={this.submit}
                    disabled={text === ''}
                >
                  {t('text.button.create')}
                </Button>
            </Paper>
        )
    }
}

export default withStyles(styles)(withTranslation()(TextUpload));
