import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next'
import {Typography} from '@material-ui/core';

import ItemComponent from '../../components/item'

const styles = theme => ({
  progress: {
    margin: '20% auto',
  },
});

class FederatedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      isLoading: props.isLoading,
      user: props.user,
      canEdit: props.canEdit
    };
  };

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const {item, isLoading, user, canEdit} = this.props;
      this.setState({item, isLoading, user, canEdit});
    }
  };

  render() {
    const {classes, t} = this.props;
    const {item, isLoading, user, canEdit} = this.state;

    if (isLoading) {
      return (
        <div style={{display: "flex"}}>
          <CircularProgress className={classes.progress} size={80}/>
        </div>
      )
    }

    if (!item && !isLoading) {
      return (
        <div className={classes.noResult}>
          <Typography>{t('no_items_found_msg')}</Typography>
        </div>
      )
    }

    return (
      <ItemComponent item={item} user={user} canEdit={canEdit}></ItemComponent>
    )
  };
}

export default withStyles(styles)(withTranslation()(FederatedSearch));
