import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import LinearProgress from '@material-ui/core/LinearProgress';

const VideoLoading = props => (
    <div>
      <ImageIcon style={{
        width: '100%',
        height: 200,
        color: '#eee'
      }}/>
      <LinearProgress />
    </div>
)

class Video extends React.Component {
    state = {
        url: null,
        loading: true
    }

    async componentWillMount() {
        const {url} = this.props.src;
        this.setState({url, loading: false});
    }

    render() {
        const {url, loading} = this.state;
        if(loading) {
            return <VideoLoading />
        }
        return (
            <video width="100%`" height="auto" controls>
                <source src={url} />
            </video>
        )
    }
}

export default Video;
