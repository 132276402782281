import React from 'react';
import { withTranslation } from 'react-i18next'

import ContainerSearch from '../../containers/search';
import TermResults from '../../components/termResults';
import FilterDrawer from '../../components/filterDrawer';
import Api from '../../api/appsync';
// import Upgrade from '../../components/upgradeUI';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    resultContainer: {
        [theme.breakpoints.up('sm')]: {}
    },
    noResult: {
        marginLeft: 40,
        marginTop: 40
    },
    filterContainer: {
        display: 'block'
    }
});

class Search extends React.Component {

    state = {
        filter: {},
        locations: null
    }

    constructor(props) {
        super(props);
        this.state = {
            filter: {
                q: props.match.params.term
            }
        };
    }

    async componentWillMount() {
        const locations = await Api.getLocations();
        this.setState({locations});
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.setState({
                filter: {
                    q: this.props.match.params.term
                }
            });
        }
    }

    render() {
        const {classes, t} = this.props;
        const {filter, locations} = this.state;

        return (
        <>
            <div className={classes.filterContainer}>
                <FilterDrawer
                  locations={locations}
                  filter={filter}
                  setFilter={filter => this.setState({filter})}/>
            </div>
            <div className={classes.resultContainer}>
                {/* <Upgrade /> */}
                <ContainerSearch filter={filter}>
                    {myProps => {
                        if(myProps.items.length > 0) {
                            return <TermResults {...myProps}/>
                        }
                        return (
                            <div className={classes.noResult}>
                                <Typography>{t('no_results_found_msg')}</Typography>
                            </div>
                        );
                    }}
                </ContainerSearch>
            </div>
        </>
        )
    }
}

export default withStyles(styles)(withTranslation()(Search));
