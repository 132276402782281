import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';

export default ({url}) => {
    const splitUrl = url.split('/');
    const lastEl = splitUrl.length - 1;
    const embeddedVideo = `https://www.youtube.com/embed/${splitUrl[lastEl]}`;
    return (
      <CardMedia
        component='iframe'
        allow="autoplay; encrypted-media"
        allowFullScreen
        frameBorder='0'
        style={{width: '100%', height:250, padding: 10}}
        src={embeddedVideo}
      />
    )
}
