import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux'
import { SignIn, Authenticator, Greetings } from 'aws-amplify-react';

import App from './App';
import "./i18n";
import './index.css';
import * as serviceWorker from './serviceWorker';
import CustomSignIn from "./components/loginForm";
import rootReducer from './reducers'

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <Authenticator hide={[SignIn, Greetings]}>
      <CustomSignIn />
      <App />
    </Authenticator>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
