// eslint-disable
// this is an auto generated file. This will be overwritten

export const createItem = `mutation CreateItem($input: CreateItemInput!) {
  createItem(input: $input) {
    id
    owner
    createdAt
    credit
    type
    tags
    transcription
    body
    location
    latLon
    coverImage
    premium
    status
    uniqueChars
    numberOfUniqueChars
    charactersBreakdown
    title
    description
    source
    licence
    maximumAge
    minimumAge
    language
    transcriptionType
    transcriptionVisibility
    pinLocation
    pinLatLon
    courseCurriculum
    upLoadedAt
  }
}
`;
export const updateItem = `mutation UpdateItem($input: UpdateItemInput!) {
  updateItem(input: $input) {
    id
    owner
    createdAt
    credit
    type
    tags
    transcription
    body
    location
    latLon
    coverImage
    premium
    status,
    uniqueChars
    numberOfUniqueChars
    charactersBreakdown
    title
    description
    source
    licence
    maximumAge
    minimumAge
    language
    transcriptionType
    transcriptionVisibility
    pinLocation
    pinLatLon
    courseCurriculum
    upLoadedAt
  }
}
`;
export const deleteItem = `mutation DeleteItem($input: DeleteItemInput!) {
  deleteItem(input: $input) {
    id
    owner
    createdAt
    credit
    type
    tags
    transcription
    body
    location
    latLon
    coverImage
    premium
    status
  }
}
`;
export const createItemLike = `mutation CreateItemLike($id: ID!) {
  createItemLike(id: $id)
}
`;
export const deleteItemLike = `mutation DeleteItemLike($id: ID!) {
  deleteItemLike(id: $id)
}
`;
export const createSubscription = `mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    id
    service
    expires
  }
}
`
