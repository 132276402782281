import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import API from '../../api/appsync';

const styles = theme => ({
  progress: {
    margin: '20% auto',
  },
});

class FederatedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      filter: {},
      from: 0,
      size: 10,
      lastSize: null,
      startSearch: props.startSearch,
      nextPageToken: null,
      selectedItem: {}
    };
  };

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.startSearch) {
      const {filter} = this.props;
      await this.setState({loading: true, filter, items: []});
      this.search();
    }
  };

  async componentWillMount() {
    await this.setState({filter: this.props.filter});
  };

  setSelected = (item) => {
    this.setState({selectedItem: item});
  };

  async search() {
    const {filter, from, size, nextPageToken} = this.state;
    const {items, nextPageToken: pageToken} = await API.federatedSearch({filter, from, size, nextPageToken});
    this.setState({
      loading: false,
      lastSize: size,
      moreItems: items.length === size,
      items: [...this.state.items, ...items],
      nextPageToken: pageToken
    });
  };

  getMoreItems = async (size = 10) => {
    const {from, lastSize} = this.state;
    await this.setState({
      from: from + lastSize,
      size
    });
    await this.search();
  };

  render() {
    const {children, classes, createFederatedItem} = this.props;
    const {items, loading, filter} = this.state;

    if(loading) {
      return <div style={{display: "flex"}}>
        <CircularProgress className={classes.progress} size={80}/>
      </div>
    }
    if (children) {
      return children({
        filter,
        items,
        getMoreItems: this.getMoreItems,
        isFederatedSearch: true,
        setSelected: this.setSelected,
        createFederatedItem
      });
    }
    console.log('Search container was given no children');
    return null;
  };
}

export default withStyles(styles)(FederatedSearch);
