import React from 'react';

import ContainerTag from '../../containers/tags';
import TagCloud from '../../components/tagCloud';
import { withRouter } from 'react-router-dom';

const styles = {
    root : {
        display: 'flex',
        justifyContent: 'center'
    }
}

export default withRouter(props => (
    <div style={styles.root}>
        <ContainerTag>
            {({tags}) => <TagCloud tags={tags} onClick={word => {
                props.history.push(`/search/${word}`);
            }}/>}
        </ContainerTag>
    </div>
))