import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar';

import ContainerFederatedSearch from '../../containers/federatedSearch';
import FederatedInputBase from '../../components/federatedSearch/inputSearch';
import FederatedInputButtons from '../../components/federatedSearch/radioFilter';
import TermResults from '../../components/termResults';
import Upload from '../../components/upload';
import Image from '../../components/federatedItemCard/image';
import Link from '../../components/link';

const styles = theme => ({
  resultContainer: {
    [theme.breakpoints.up('sm')]: {}
  },
  header: {
    background: 'transparent'
  },
  noResult: {
    marginLeft: 40,
    marginTop: 40
  },
  filterContainer: {
    display: 'block'
  }
});

class FederatedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'all',
      term: '',
      startSearch: false,
      createFederatedItem: false,
      item: {},
      suggestedTags: null
    };
  }

  createFederatedItem = async (item, suggestedTags) => {
    const {
      type: itemType,
      url,
      title,
      source,
      thumbnail
    } = item;

    let preview = <Link body={{url}} />;
    let body = {url};
    let type = 'link';

    if (suggestedTags) {
      this.setState({suggestedTags: [...suggestedTags]});
    }

    switch (source) {
      case 'Youtube':
        const splitUrl = url.split('/');
        const lastIndex = splitUrl.length - 1;
        const id = splitUrl[lastIndex];
        body = {youtubeId: id, url};
        preview = <iframe title="Youtube preview" width="100%" height="315" src={`https://www.youtube.com/embed/${id}`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
        type = 'youtube';
        break;
      case 'Google Images':
        preview = <Image title="Google images preview" width="100%" height="315" url={`${url}`} frameBorder="0" allowFullScreen></Image>
        type = 'image';
        break;
      default:
        break;
    }

    let resultItem = {
      type,
      preview,
      isFederateSource: source,
      federatedType: itemType,
      description: title,
      coverImage: thumbnail,
      body
    };

    this.setState({createFederatedItem: true, item: resultItem});
  };

  render() {
    const {classes, t} = this.props;
    const {term, type, startSearch, createFederatedItem, item, suggestedTags} = this.state;
    const filter = {term, type};
    let noResultsMessage = '';

    for (const key in this.state) {
      noResultsMessage = `${this.state[key]} ${noResultsMessage}`
    }

    if (createFederatedItem && item) {
      return (
        <div>
          <Upload result={item} type={item.type} suggestedTags={suggestedTags}/>
        </div>
      )
    }

    return (
      <div>
        <div className={classes.filterContainer}>
          <AppBar position="static" elevation={0} className={classes.header}>
            <FederatedInputBase
              onChange={event => {
                this.setState({term: event.target.value, startSearch: false})
              }}
              onAdvancedPress={event => {
                this.setState({term: event.target.value})
              }}
              onSearchPress={event => {
                this.setState({term: this.state.term, startSearch: true})
              }}
              value={term}
              onKeyDown={event => {
                if(event.keyCode === 13) {
                  this.setState({term: event.target.value, startSearch: true})
                }
              }}
            />
          </AppBar>
          <FederatedInputButtons
            setType={type => this.setState({type})}/>
        </div>
        <div className={classes.resultContainer}>
          <ContainerFederatedSearch filter={filter} startSearch={startSearch} createFederatedItem={this.createFederatedItem}>
            {myProps => {
              if(myProps.items.length > 0) {
                return <TermResults {...myProps}/>
              }
              return (
                <div className={classes.noResult}>
                  <Typography>{t('try_searching_msg')}</Typography>
                </div>
              );
            }}
          </ContainerFederatedSearch>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation()(FederatedSearch));
