import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {convertToRaw, convertFromRaw, EditorState, ContentState} from 'draft-js';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(theme => ({
  editorContainerDiv: {
    minHeight: theme.spacing(30),
    border: 'solid 1px grey',
    marginBottom: theme.spacing(1)
  },
  editorContainerBorderlessDiv: {
    minHeight: theme.spacing(30),
    marginBottom: theme.spacing(1)
  }
}));

const BasicEditor = (props) => {
  const classes = useStyles();
  let initialState = '';

  //When instantiating an new editor with data, it will try to render draftjs data first, then markdown and fallback to plain text last
  if (props.text && !props.richTextMarkdown && !props.richTextDraftJs) {
    initialState = EditorState.createWithContent(ContentState.createFromText(props.text));
  }

  if (props.richTextMarkdown && !props.richTextDraftJs) {
    let data;
    try {
      data = JSON.parse(props.richTextMarkdown);
    } catch (err) {
      data = props.richTextMarkdown;
    }
    const rawObj = markdownToDraft(data);
    initialState = EditorState.createWithContent(convertFromRaw(rawObj));
  }

  if (props.richTextDraftJs) {
    let data;
    try {
      data = JSON.parse(props.richTextDraftJs);
    } catch (err) {
      data = props.richTextDraftJs;
    }
    initialState = EditorState.createWithContent(convertFromRaw(data));
  }

  if (!props.richTextMarkdown && !props.richTextDraftJs && !props.text) {
    initialState = EditorState.createEmpty();
  }

  const [editorState, setEditorState] = useState(initialState);
  const [rawJsContent, setRawJsContent] = useState(''); // eslint-disable-line no-unused-vars
  const [text, setText] = useState(''); // eslint-disable-line no-unused-vars
  const [markdown, setMarkdown] = useState(''); // eslint-disable-line no-unused-vars

  const onChange = (data) => {
    const currentContent = data.getCurrentContent();
    const rawJsContent = convertToRaw(currentContent);
    const text = currentContent.getPlainText();
    const markdown = JSON.stringify(draftToMarkdown(rawJsContent));
    const rawJsString = JSON.stringify(rawJsContent)

    setEditorState(data);
    setRawJsContent(rawJsString);
    setText(text);
    setMarkdown(markdown);
    props.onChange({text, rawJsContent: rawJsString, markdown});
  }

  return (
    <div className={props.readOnly ? classes.editorContainerBorderlessDiv : classes.editorContainerDiv}>
      <Editor
        toolbarHidden={props.readOnly}
        editorState={editorState}
        onEditorStateChange={onChange}
        readOnly={props.readOnly}
        placeholder={props.label || ''}
        toolbar={{
          options: ['inline', 'blockType', 'history'],
          inline: {
            inDropdown: true,
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
          },
          history: { inDropdown: true },
        }}
      />
    </div>
  )
}

export default BasicEditor
