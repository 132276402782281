import React from 'react';
import Link from '../link';
import Image from '../image';
import Video from '../video';
import Text from '../text';
import Audio from '../audio';

const Preview = ({type, id, url, text}) => {
  let result = '';
  switch (type) {
    case 'youtube':
      result = <iframe title="Youtube preview" width="100%" height="315" src={`https://www.youtube.com/embed/${id}`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
      break;
    case 'basicLink':
      result = <Link body={{url}} />
      break;
    case 'image':
      result = <Image src={url} style={{width: '100%'}}/>
      break;
    case 'video':
      result = <Video src={url} style={{width: '100%'}}/>
      break;
    case 'text':
      result = <Text text={text}/>
      break;
    case 'audio':
      result = <Audio src={url} style={{width: '100%'}}/>
      break;
    default:
      result = <div></div>;
  }

  return result;
}

export default Preview;
