import React from 'react';
import WordCloud from 'react-d3-cloud';
 
import './style.css';

export default ({tags, onClick}) => {

    let total = 0;
    for(let i = 0, l = tags.length; i < l; i++) {
        if(tags[i]['text'] === '词') {
            tags.splice(i, 1);
            --l;
            continue;
        }
        total += tags[i].value;
    }
    const ratio = 100 / total;
    const fontSizeMapper = word => ratio * word.value * 8;
    const rotate = word => ratio * word.value % 360;

    return <WordCloud
        data={tags}
        fontSizeMapper={fontSizeMapper}
        rotate={rotate}
        onWordClick={evt => onClick(evt.text)}
    />
}