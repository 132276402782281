import React from 'react';
import MovieIcon from '@material-ui/icons/Movie';
import { withTranslation } from 'react-i18next'

import Message from '../message';
import API from '../../api/appsync';
import Progress from './progress';
import Upload from './upload';
import {detectVideoRegex, detectYoutubeEmbeddedLinkRegex} from './uploadLinkHelpers';
import Preview from './uploadPreviewHelpers';
import LinkInput from './linkInput';

class VideoCreator extends React.Component {

    state = {
        uploading: false,
        progress: 0,
        error: null
    }

    onPick = async (data, fromLink) => {
        const {t, onDone} = this.props;

        let body;
        let preview;
        let type = 'video';

        if (!fromLink && !detectVideoRegex.test(data.type)) {
            this.setState({error: `${t('upload.video.please_select_error')}`})
            return;
        }

        if (fromLink) {
            type = 'youtube'
            var match = data.match(detectYoutubeEmbeddedLinkRegex);
            const id = (match && match[7].length===11) ? match[7] : false;

            if (!id) {
                this.setState({error: `${t('upload.link.no_id_error')}`});
                return;
            } else {
                this.setState({error: ''});
            }

            body = {youtubeId: id};
            preview =  Preview({type, id});
        } else {
            this.setState({uploading: true, progress: 0});

            const progressCallback = progress => {
                this.setState({
                    progress: progress.loaded/progress.total
                });
            }
            body = await API.uploadFile({progressCallback, data});
            preview =  Preview({type, url: body});
        }

        if (onDone) {
            onDone('video', {
                body,
                preview
            })
        }
    }

    render() {
        const {t} = this.props;
        const {uploading, progress, error} = this.state;
        if (uploading) {
            return (
                <Progress progress={progress/100}/>
            )
        }
        return (
            <>
                {error !== null &&
                    <Message type='error' message={error} onClose={_ => this.setState({error: null})}/>
                }
                <Upload
                    content={<MovieIcon fontSize='inherit'/>}
                    onPick={this.onPick}
                    accept=".flv,.mp4,.m3u8,.ts,.3gp,.mov,.avi,.wmv;capture=camcorder"
                    text={t('upload.video.drag_and_drop')}
                    title="Video"
                    extraContent={
                        <LinkInput
                          onChange={(value) => this.onPick(value, true)}
                        />
                    }
                />
            </>
        );
    }
}

export default withTranslation()(VideoCreator);
