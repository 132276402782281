import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker
} from "react-google-maps";

class MapWithMarkers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: '',
      lng: ''
    };
  }

  componentWillMount() {
    const {isMarkerShown} = this.props;

    if (isMarkerShown) {
      this.setState({lat: this.props.center.lat, lng: this.props.center.lng});
    }
  }

  addMarker(e) {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    this.setState({lat, lng});
    this.props.onMapClicked({lat, lng});
  }

  render() {
    const {lat, lng} = this.state;
    return (
      <GoogleMap
        onClick={this.addMarker.bind(this)}
        defaultZoom={this.props.zoom}
        defaultCenter={this.props.center}
      >
        {(lat && lng) &&
        <Marker
          key="pin-id-key"
          position={{ lat: lat, lng: lng }}
        />}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(MapWithMarkers));
