import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import Grid from '@material-ui/core/Grid';
import API from '../../api/appsync';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'italic',
        color: 'inherit'
    },
    detailLink: {
        color: 'inherit',
        textDecoration: 'none'
    },
    icon: {
        margin: theme.spacing(),
    },
    link: {
        color: 'inherit'
    },
    previewTitle: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing()
    },
    previewImage: {
        width: '100%',
        height: 100,
        objectFit: 'contain',
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing()
    },
    descUrl: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing()
    },
    desc: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing()
    }
})

const BasicLink = withStyles(styles)(({body, parsed, classes}) => (
    <div className={classes.root}>
        <LinkIcon className={classes.icon}/>
        <a
            rel="noopener noreferrer"
            target="_blank"
            href={body.url}
            className={classes.link}
        >
            {parsed.hostname}
        </a>
    </div>
))

function shrinkText(text, length) {
    if(text.length > length) {
        return `${text.substring(0, length-3)}...`
    }
    return text;
}

const DetailLink = withStyles(styles)(({body, parsed, classes, detail}) => (
    <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h6" className={classes.previewTitle}>{shrinkText(detail.title, 60)}</Typography>
            </Grid>
            {detail.image &&
            <Grid item xs={5}>
                {
                <img className={classes.previewImage} src={detail.image} alt={detail.title}/>
                }
            </Grid>
            }
            <Grid item xs={detail.image ? 7 : 12}>
                <Typography variant="body2" className={classes.desc}>
                    {shrinkText(detail.description, 180)}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{padding: 0, paddingLeft: 12}}>
                <Typography variant="body2" className={classes.descUrl}>{shrinkText(detail.url, 32)}</Typography>
            </Grid>
        </Grid>
    </div>
))

class Link extends React.Component {

    state = {
        detail: null
    }

    async componentWillMount() {
        const detail = await API.linkDetail(this.props.body.url);
        if(!detail.description || !detail.title) {
            return;
        }
        this.setState({detail});
    }

    render() {
        const {detail} = this.state;
        const {body, classes} = this.props;
        var parsed = document.createElement('a');
        parsed.href = body.url;
        if(detail === null) {
            return <BasicLink parsed={parsed} {...this.props} />;
        }
        return (
            <a href={body.url} alt={detail.title} className={classes.detailLink}>
                <DetailLink parsed={parsed} detail={detail} {...this.props} />
            </a>
        )
    }
}

export default withStyles(styles)(Link);