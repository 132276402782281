import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import VideocamIcon from '@material-ui/icons/Videocam';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withTranslation } from 'react-i18next'

import MyCardMedia from './media';

const styles = theme => ({
  card: {
    maxWidth: 250,
    margin: '0 auto',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatarIcon: {
    fontSize: 'medium',
    float: 'right'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardActionSelected: {
    display: 'block',
    textAlign: 'initial',
    width: '100%',
    height: '100%',
    border: 'solid #289c28'
  },
  cardActionUnselected: {
    display: 'block',
    textAlign: 'initial',
    width: '100%',
    height: '100%'
  }
});

class FederatedItemCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      newTag: '',
      tags: null,
      transcription: null,
      updating: false,
      coverImage: null,
      status: this.props.status,
      isSelected: false,
      selectedClass: props.classes.cardActionUnselected
    };
  }

  componentDidUpdate(prevProps) {
    const {classes} = this.props;
    if (prevProps !== this.props) {
      if (this.props.selectedItemKey === this.props.itemKey) {
        this.setState({isSelected: true, selectedClass: classes.cardActionSelected});
      } else {
        this.setState({isSelected: false, selectedClass: classes.cardActionUnselected});
      }
    }
  }

  selectItem = _ => {
    const {
      url,
      type,
      source,
      title,
      thumbnail,
      itemKey
    } = this.props;

    const item = {url, title, source, type, thumbnail};

    this.props.selectItem(itemKey, item);
  };

  selectAvatarIcon(type) {
    const {classes} = this.props;
    switch (type) {
      case 'image':
        return (<PhotoCameraIcon className={classes.avatarIcon}/>);
      case 'video':
        return (<VideocamIcon className={classes.avatarIcon}/>);
      case 'audio':
        return (<VolumeUpIcon className={classes.avatarIcon}/>);
      default:
        return null;
    }
  }

  render() {
    const { classes, type, url, title, source, key, t} = this.props;
    const {selectedClass, isSelected} = this.state;

    return (
      <Card className={classes.card} key={key}>
        <ButtonBase
          className={classNames(selectedClass)}
          onClick={this.selectItem}
        >
          <CardHeader
            title={title}
            avatar={this.selectAvatarIcon(type)}
          />
          <CardContent style={{marginBottom: 1, width: '100%', height: '100%'}}>
            <Typography style={{fontWeight: 'bold'}} variant="body2" color="textSecondary" component="p" gutterBottom>
              {t('federated_item_card.source')}: {source}
            </Typography>
            <Typography style={{fontWeight: 'bold'}} variant="body2" color="textSecondary" component="p" gutterBottom noWrap>
              {t('federated_item_card.url')}: {url}
            </Typography>
            <Typography style={{fontWeight: 'bold'}} variant="body2" color="textSecondary" component="p" gutterBottom>
              {t('federated_item_card.type')}: {type}
            </Typography>
          </CardContent>
          <MyCardMedia {...this.props} className={classes.media} isSelected={isSelected}/>
        </ButtonBase>
      </Card>
    );
  }
}

FederatedItemCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(FederatedItemCard)));
