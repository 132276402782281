import React from 'react';
// import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    padding: '35px 40px!important',
    width: '100%',
    margin: '5% auto 50px',
    cursor: 'pointer'
  },
  box: {
    width: '100%',
    height: 200,
    marginBottom: 30,
    border: '2px dotted #828282',
    fontSize: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#bbb'
  },
  progress: {
    margin: '20% auto',
  },
  progressSmall: {
    margin: '-266px auto 0'
  },
  button: {
    borderRadius: 0,
    boxShadow: 'none',
    fontSize: 12,
    fontWeight: 400,
    padding: '14px 0'
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    color: '#152939'
  },
  text: {
      fontSize: 16,
      fontWeight: 400,
      marginTop: 4,
      marginBottom: 24,
      color: '#828282'
  }
});

function onDragOver(evt) {
  evt.preventDefault();
}

function onDrop(onPick, evt) {
  evt.preventDefault();
  onPick(evt.dataTransfer.files[0]);
}

const Upload = ({classes, accept, title, text, content, onPick, extraContent}) => (
    <div onDrop={onDrop.bind(null, onPick)} onDragOver={onDragOver}>
        <input 
            accept={accept}
            onChange={evt => onPick(evt.target.files[0])}
            className={classes.input} 
            id="raised-button-file" 
            type="file"
            style={{display: 'none'}}
        /> 
        <label htmlFor="raised-button-file"> 
            <Paper className={classes.root} elevation={1}>
                <div className={classes.title}>{title}</div>
                <div className={classes.text}>{text}</div>
                <div className={classes.box}>
                    {content}
                </div>
                {extraContent}
                {/* <Button fullWidth variant="contained" color="primary" component="span" className={classes.button}> 
                    Select {title} 
                </Button>  */}
            </Paper>
        </label>
    </div>
)

export default withStyles(styles)(Upload);