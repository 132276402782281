import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Message from '../message';
import { setWhgPremiumUser } from '../../actions';
import API from '../../api/appsync';

const products = [
  { name: 'Wo Hui Go', desc: 'Monthly subscription', price: '$10 (SGD) / month' }
];

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }
}));

const review = props => {
  const classes = useStyles();
  const {onBack, onNext, address, source} = props;
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleNext = async _ => {
    setSubmitted(true);
    setErrorMessage(null);
    try {
      await API.createSubscription({service: 'whg', source});
      setSubmitted(false);
      props.dispatch(setWhgPremiumUser(true));
      onNext();
    } catch(e) {
      setErrorMessage(e.message);
      setSubmitted(false);
    }
  }

  return (
    <React.Fragment>
      {errorMessage !== null &&
        <Message type='error' message={errorMessage} onClose={_ => setErrorMessage(null)}/>
      }
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {products.map(product => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Billing
          </Typography>
          <Typography>{source.card.name}</Typography>
          <Typography>{address.address_line1}</Typography>
          <Typography>{address.address_line2}</Typography>
          <Typography>{address.address_city}</Typography>
          <Typography>{address.address_zip}</Typography>
          <Typography>{address.address_country}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Typography gutterBottom>Card Type</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{source.card.brand}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>Card number</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>xxxx-xxxx-xxxx-{source.card.last4}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>Expiry date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{source.card.exp_month.toString().padStart(2, '0')} / {source.card.exp_year - 2000}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          disabled={submitted}
          onClick={onBack}
          className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={submitted}
          className={classes.button}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
}

export default connect()(review);