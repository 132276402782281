import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {Elements, injectStripe, CardExpiryElement, CardNumberElement, CardCVCElement} from 'react-stripe-elements';
import Button from '@material-ui/core/Button';

import StripeElementWrapper from './StripeElementWrapper';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }
})

class _PaymentForm extends React.Component {

  submit = async _ => {
    const {onNext, address} = this.props;
    const {name} = this.state;
    let {token} = await this.props.stripe.createToken({...address, name});
    if(!token || token.hasOwnProperty('error')) return;
    onNext(token);
    return;
  }

  state = {
    name: ''
  }

  render() {
    const {classes, onBack} = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Payment method
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Name on card"
              fullWidth
              style={{marginTop: '14px'}}
              onChange={evt => this.setState({name: evt.target.value})}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StripeElementWrapper
              onChange={evt => console.log(evt)}
              label="Card Number"
              component={CardNumberElement} />
          </Grid>
          <Grid item xs={12} md={6}>
            <StripeElementWrapper label="Expiry (MM / YY)" component={CardExpiryElement} />
          </Grid>
          <Grid item xs={12} md={6}>
            <StripeElementWrapper label="CVC" component={CardCVCElement} />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button onClick={onBack} className={classes.button}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.submit}
            className={classes.button}
          >
            Next
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const CheckoutForm = withStyles(styles)(injectStripe(_PaymentForm));

export default props => (
  <Elements>
    <CheckoutForm {...props}/>
  </Elements>
)