import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import API from '../../api/appsync';

const styles = theme => ({
    progress: {
      margin: '20% auto',
    },
});

class Tags extends React.Component {

    state = {
        tags: null,
        loading: true
    }

    componentWillMount() {
        this.updateAll();
    }

    async updateAll() {
        this.setState({loading: true});
        let tags = await API.getAllTags();
        this.setState({loading: false, tags});
    }

    render() {
        const {children, classes} = this.props;
        const {tags, loading} = this.state;

        if(loading) {
            return <CircularProgress className={classes.progress} size={80}/>;
        }

        if (children) {
            return children({tags});
        }
        console.log('Tags container was given no children');
        return null;
    }
}

export default withStyles(styles)(Tags);