import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import TranslateIcon from '@material-ui/icons/Translate';

const useStyles = makeStyles(theme => ({
  alignSelect: {
    display: 'flex'
  },
  centreTranslateIcon: {
    marginBottom: theme.spacing(0.7),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5)
  },
  selectText: {
    color: theme.palette.primary.contrastText
  },
  selectIcon: {
    color: theme.palette.primary.contrastText
  }
}));

const LanguageSelector = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = ({ target: { value } }) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  }

  return (
    <div className={classes.alignSelect}>
      <div className={classes.centreTranslateIcon}>
        <TranslateIcon key='change-language-key'/>
      </div>
      <Select
        id="select-app-language"
        disableUnderline={true}
        classes={{root: classes.selectText, icon: classes.selectIcon}}
        value={language}
        onChange={changeLanguage}
      >
        <MenuItem value='en-GB'>English</MenuItem>
        <MenuItem value='zh-HK'>中文普通话</MenuItem>
      </Select>
    </div>
  )
}

export default LanguageSelector
