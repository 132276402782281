import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import Completed from './Completed';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  }
});

const steps = ['Billing address', 'Payment details', 'Review your order', 'Completed'];

const StepContent = ({step, onBack, onNext, address, source}) => {
  switch (step) {
    case 0:
      return <AddressForm onNext={onNext} />;
    case 1:
      return <PaymentForm onBack={onBack} onNext={onNext} address={address}/>;
    case 2:
      return <Review onBack={onBack} onNext={onNext} source={source} address={address}/>;
    case 3:
      return <Completed />;
    default:
      throw new Error('Unknown step');
  }
};

class _Checkout extends React.Component {

  state = {
    activeStep: 0,
    address: null,
    source: null
  }

  handleNext = data => {
    let {activeStep} = this.state;
    let state = {};
    switch(activeStep) {
      case 0:
        state['address'] = data;
        break;
      case 1:
        state['source'] = data;
        break;
      default:
        break;
    }
    state['activeStep'] = ++activeStep;
    this.setState(state);
  };

  handleBack = () => {
    let {activeStep} = this.state;
    activeStep--
    this.setState({activeStep});
  };

  render() {
    const {classes} = this.props;
    const {activeStep, address, source} = this.state;

    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          Subscribe
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          <StepContent
            step={activeStep}
            onBack={this.handleBack}
            onNext={this.handleNext}
            address={address}
            source={source}
          />
        </React.Fragment>
      </Paper>
    );
  }
}

const Checkout = withStyles(styles)(_Checkout);

export default _ => (
    <StripeProvider apiKey="pk_test_ig0Qp5u7JnTTgbOs6FxOef4k00rwo554nw">
      <Checkout />
    </StripeProvider>
)