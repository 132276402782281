import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }
});

class AddressForm extends React.Component {

  state = {
    address_line1: '',
    address_line2: '',
    address_city: '',
    address_zip: '',
    address_country: ''
  }

  handleChange = name => evt => {
    this.setState({[name]: evt.target.value});
  }

  render() {
    const {classes, onNext} = this.props;
    const {address_line1, address_city, address_zip, address_country} = this.state;
    let disabled = false;
    if(address_line1 === '' || address_city === '' || address_zip === '' || address_country === '') {
      disabled = true;
    }

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Billing address
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              onChange={this.handleChange("address_line1")}
              label="Address line 1"
              fullWidth
              autoComplete="billing address-line1"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address2"
              onChange={this.handleChange("address_line2")}
              label="Address line 2"
              fullWidth
              autoComplete="billing address-line2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              onChange={this.handleChange("address_city")}
              label="City"
              fullWidth
              autoComplete="billing address-level2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="state" name="state" label="State/Province/Region" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              onChange={this.handleChange("address_zip")}
              label="Zip / Postal code"
              fullWidth
              autoComplete="billing postal-code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="country"
              name="country"
              onChange={this.handleChange("address_country")}
              label="Country"
              fullWidth
              autoComplete="billing country"
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={_ => onNext(this.state)}
            className={classes.button}
            disabled={disabled}
          >
            Next
          </Button>
          </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AddressForm);