import React, {Component} from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

class ItemModal extends Component {
  render() {
    const {classes, children, isOpen, closeModal} = this.props;
    return (
      <Modal
        className={classes.modal}
        open={isOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.modalCard}>
            {children}
          </div>
        </Fade>
      </Modal>
    )
  }
}

export default ItemModal
