import React from 'react';
import { ReactMicPlus } from 'react-mic-plus';
import Fab from '@material-ui/core/Fab';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop'
import AudioFile from '@material-ui/icons/Audiotrack';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next'

import API from '../../api/appsync';
import Message from '../message';
import Upload from './upload';
import Progress from './progress';
import Preview from './uploadPreviewHelpers';
import LinkInput from './linkInput';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    },
    progress: {
      margin: '20% auto',
    },
    progressSmall: {
      margin: '-266px auto 0'
    },
    fab: {
      margin: theme.spacing(3),
    },
    waiting: {
        position: 'absolute',
        marginTop: -50,
        width: 640,
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    soundWave: {
        borderRadius: 20,
        width: '100%'
    },
    recordText: {
        fontSize: 16,
        fontWeight: 400,
        color: '#828282'
    },
    recordBox: {
        border: '2px solid #bbb',
        backgroundColor: '#eee',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center'
    }
})

class UploadAudio extends React.Component {

    state = {
      record: false,
      uploading: false,
      progress: 0,
      error: null,
      width: '100%'
    }

    onStop = async recordedBlob => {
        let data = recordedBlob.blob;
        data.name = 'audio.wav';
        return this.onPick(data);
    }

    onPick = async (data, fromLink) => {
        const {onDone} = this.props;
        let body;

        if (fromLink) {
            body = {url: data};
        } else {
            this.setState({uploading: true, progress: 0});

            const progressCallback = progress => {
                this.setState({
                    progress: progress.loaded/progress.total
                });
            }

            body = await API.uploadFile({progressCallback, data});
        }

        if (onDone) {
            onDone('audio', {
                body,
                preview: Preview({type: 'audio', url: body})
            })
        }
    }

    render() {
        const {uploading, progress, record, error} = this.state;
        const {theme, classes, t} = this.props;
        const primaryText = '#fff';
        const primaryColor = theme.palette.primary.main;

        if(uploading) {
            return <Progress progress={progress/100}/>;
        }

        return (
            <>
                {error !== null &&
                    <Message type='error' message={error} onClose={_ => this.setState({error: null})}/>
                }
                {!record &&
                <>
                    <Upload
                        content={<AudioFile fontSize='inherit'/>}
                        extraContent={
                            <>
                            <p className={classes.recordText}>{t('upload.audio.click_to_record')}</p>
                            <div className={classes.recordBox}>
                                <Fab
                                    onClick={evt => {
                                        evt.preventDefault();
                                        this.setState({record: true});
                                    }}
                                    color="secondary"
                                    aria-label="Add"
                                    className={classes.fab}>
                                        <MicIcon />
                                </Fab>
                            </div>
                            <LinkInput onChange={(value) => this.onPick(value, true)}/>
                            </>
                        }
                        onPick={this.onPick}
                        accept="audio/*"
                        text="Drag-and-drop or click to select."
                        title="Audio"
                    />

                </>
                }
                <div style={{display: record ? 'block' : 'none', margin: '20px auto'}}>
                    <ReactMicPlus
                        record={record}
                        className={classes.soundWave}
                        onStop={this.onStop}
                        strokeColor={primaryText}
                        backgroundColor={primaryColor}
                        nonstop={true}
                        mimeType='audio/wav'
                        duration={5} />
                </div>
                {record &&
                <Fab
                    onClick={_ => this.setState({record: false})}
                    color="secondary"
                    aria-label="Stop"
                    style={{margin: '10px auto'}}
                    className={classes.fab}>
                        <StopIcon />
                </Fab>
                }
            </>
        );
    }
}

export default withStyles(styles)(withTheme(withTranslation()(UploadAudio)));
