import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import API from '../../api/appsync';

const styles = theme => ({
    progress: {
      margin: '20% auto',
    },
});

class Search extends React.Component {

    state = {
        items: [],
        loading: true,
        filter: {},
        from: 0,
        size: 10,
        moreItems: false,
        lastSize: null
    }

    async componentDidUpdate(prevProps) {
        if (this.props.filter !== prevProps.filter) {
            const {filter} = this.props;
            await this.setState({loading: true, filter, items: []});
            this.search();
        }
    }

    async componentWillMount() {
        await this.setState({filter: this.props.filter});
        this.search();
    }

    async search() {
        const {filter, from, size} = this.state;
        const {items} = await API.search({filter, from, size});
        this.setState({
            loading: false,
            lastSize: size,
            moreItems: items.length === size,
            items: [...this.state.items, ...items]
        });
    }

    deleteItem = async id => {
        let {items} = this.state;
        await API.deleteItem(id);
        const itemIndex = items.findIndex(({id: _id}) => _id === id);
        items.splice(itemIndex, 1);
        this.setState({items: [...items]});
    }

    updateItem = async item => {
        let {items} = this.state;
        const newItem = await API.updateItem(item);
        const itemIndex = items.findIndex(({id: _id}) => _id === item.id);
        items[itemIndex] = newItem;
        this.setState({items: [...items]});
    }

    getMoreItems = async (size = 10) => {
        const {from, lastSize} = this.state;
        await this.setState({
            from: from + lastSize,
            size
        })
        await this.search();
    }

    render() {
        const {children, classes} = this.props;
        const {items, loading, moreItems, filter} = this.state;

        if(loading) {
            return <div style={{display: "flex"}}>
                <CircularProgress className={classes.progress} size={80}/>
            </div>
        }
        if (children) {
            return children({
                filter,
                items,
                updateItem: this.updateItem,
                getMoreItems: moreItems ? this.getMoreItems : null,
                deleteItem: this.deleteItem
            });
        }
        console.log('Search container was given no children');
        return null;
    }
}

export default withStyles(styles)(Search);
