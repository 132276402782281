import React from 'react';
import Typography from '@material-ui/core/Typography';

export default props => (
    <React.Fragment>
        <Typography variant="h5" gutterBottom>
            Thank you for your subscription.
        </Typography>
        <Typography variant="subtitle1">
            You have paid your first month and we have set up a monthly subscription charge for you. You can now immediately enjoy our premium content. 
        </Typography>
    </React.Fragment>
)