import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Image from '@material-ui/icons/Image';
import Mic from '@material-ui/icons/Mic';
import YouTubeIcon from '@material-ui/icons/YouTube';
import NoMic from '@material-ui/icons/MicOff';
import Movie from '@material-ui/icons/Movie';
import Text from '@material-ui/icons/Notes';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Upload from '../../components/upload';

const styles = theme => ({
    heading: {
        marginTop: theme.spacing(),
        textAlign: 'center'
    },
    root: {
      flexGrow: 1,
      maxWidth: 450,
      margin: '0 auto'
    },
    notice: {
        textAlign: 'center',
        width: 140,
        fontSize: '0.7rem',
        color: '#aaa'
    },
    buttonWrapper: {
        width: 120,
        margin: '0 auto'
    },
    button: {
      fontSize: '6em'
    },
});

const Selection = withStyles(styles)(({classes}) => {
    const {t} = useTranslation();
    let audioLink = null;
    if (navigator.getUserMedia !== undefined) {
        audioLink = (
            <Link to="/upload/audio"><IconButton className={classes.button} aria-label="audio">
                <Mic style={{fontSize: 'inherit'}} />
            </IconButton></Link>
        );
    } else {
        audioLink = (
            <div>
                <IconButton className={classes.button} disabled aria-label="video">
                    <NoMic style={{fontSize: 'inherit'}} />
                </IconButton>
                <Typography className={classes.notice}>{t('no_browser_support_msg')}</Typography>
            </div>
        )
    }

    return(
    <>
    <Typography variant="h5" className={classes.heading} gutterBottom>{t('what_to_create_msg')}</Typography>
    <Grid container className={classes.root} spacing={16} >
        <Grid item xs={6}>
            <div className={classes.buttonWrapper}>
            <Link to="/upload/image"><IconButton className={classes.button} aria-label="image">
                <Image style={{fontSize: 'inherit'}}/>
            </IconButton></Link>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div className={classes.buttonWrapper}>
            <Link to="/upload/youtube"><IconButton className={classes.button} aria-label="youtube">
                <YouTubeIcon style={{fontSize: 'inherit'}}/>
            </IconButton></Link>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div className={classes.buttonWrapper}>
            {audioLink}
            </div>
        </Grid>
        <Grid item xs={6}>
            <div className={classes.buttonWrapper}>
            <Link to="/upload/video"><IconButton className={classes.button} aria-label="video">
                <Movie style={{fontSize: 'inherit'}} />
            </IconButton></Link>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div className={classes.buttonWrapper}>
            <Link to="/upload/text"><IconButton className={classes.button} aria-label="text">
                <Text style={{fontSize: 'inherit'}} />
            </IconButton></Link>
            </div>
        </Grid>
    </Grid>
    </>)
});

export default props => {
    switch(props.match.params.type) {
        case 'image':
        case 'audio':
        case 'video':
        case 'link':
        case 'text':
            return <Upload {...props.match.params}/>;
        case 'youtube':
            return <Redirect to='/upload/link'/>
        default:
            return <Selection/>
    }
}
