import React from 'react';
import {withStyles} from '@material-ui/styles';
import {isEmpty} from 'lodash';

import API from '../../../api/appsync';
import ContainerItem from '../../../containers/item';

const styles = theme => ({
  resultContainer: {
    [theme.breakpoints.up('sm')]: {}
  },
  header: {
    background: 'transparent'
  },
  noResult: {
    marginLeft: 40,
    marginTop: 40
  },
  itemContainer: {
    display: 'block'
  }
});

class PageItemView extends React.Component {
  constructor(props) {
    super(props);
    let item;
    let previousPage;
    let user;
    let canEdit;

    try {
      item = JSON.parse(props.location.state.item);
      previousPage = JSON.parse(props.location.state.pageProps);
      user = props.location.state.userProps;
      canEdit = props.location.state.canEdit;
    } catch (err) {
      item = {};
      previousPage = {};
      user = {};
      canEdit = false;
    }

    this.state = {
      itemId: props.match.params.itemId,
      item,
      user,
      previousPage,
      canEdit,
      isLoading: true
    };
  }

  async componentWillMount() {
    let {item, itemId, user, canEdit} = this.state;

    if (isEmpty(item)) {
      item = await API.getItemById(itemId);
    }

    const {owner_username, owner_picture} = await API.getUserNameBySub(item.owner);
    item.owner_username = owner_username;
    item.owner_picture = owner_picture;

    if (isEmpty(user)) {
      const {userName: username} = await API.getUsername();

      user = {
        username
      };
    }

    if (!canEdit) {
      canEdit = await API.canUserEdit();
    }

    const isLoading = false;

    this.setState({item, user, canEdit, isLoading});
  }

  render() {
    const {item, user, canEdit, isLoading} = this.state;
    const {classes} = this.props;

    return (
      <div className={classes.itemContainer}>
        <div className={classes.resultContainer}>
          <ContainerItem item={item} user={user} canEdit={canEdit} isLoading={isLoading}></ContainerItem>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PageItemView);
