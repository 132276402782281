import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next'

import SearchInput from './searchInput';

const styles = theme => ({
  root: {
    width: '100%'
  },
  header: {
    background: 'transparent'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  button: {
    margin: theme.spacing(1),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.9),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.9),
    },
    color: '#000',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '100%',
      maxWidth: 230
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  searchIcon: {
    width: theme.spacing(4),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(4),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary
  },
  tabsScrollButton: {
    backgroundColor: theme.palette.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4)
  },
});

class SecondaryHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      search: '',
      tab: 0
    };

    this.tabLinks = ['/search', '/profile/me', '/federated-search'];

    if(/^\/search\/.*/.test(this.props.location.pathname)) {
      this.state.search = /^\/search\/(.*)/.exec(this.props.location.pathname)[1];
      this.state.tab = 0;
    }

    if(/^\/profile\/*.*/.test(this.props.location.pathname)) {
      this.state.tab = 1;
    }

    if(/^\/federated-search\//.test(this.props.location.pathname)) {
      this.state.tab = 2;
    }
  }

  componentWillMount() {
    if (/^\/search\/*.*/.test(this.props.location.pathname)) {
      this.setState(({tab: 0}))
    }

    if (/^\/profile\/*.*/.test(this.props.location.pathname)) {
      this.setState(({tab: 1}))
    }

    if (/^\/federated-search\/*.*/.test(this.props.location.pathname)) {
      this.setState(({tab: 2}))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const searchArr = /^\/search\/(.*)/.exec(this.props.location.pathname);
      const search = searchArr === null ? '' : searchArr[1];
      this.setState({search})
    }
  }

  handleChangeSelection = (event, tab) => {
    this.setState({tab});
    this.props.history.push(`${this.tabLinks[tab]}`)
  };

  render() {
    let { search, tab } = this.state;
    const { classes, t } = this.props;
    const tabsToDisplay = ['view_existing_media', 'view_my_media', 'find_media_online'];

    return (
      <div className={classes.root}>
        <AppBar position="static" elevation={0} className={classes.header}>
          <Toolbar>
            <Tabs
              classes={{scrollButtons: classes.tabsScrollButton}}
              value={tab}
              onChange={this.handleChangeSelection}
              indicatorColor='secondary'
              textColor='secondary'
              variant='scrollable'
              scrollButtons='off'
            >
              {tabsToDisplay.map((tabLabel, index) => {
                const label = t(`secondary_header.navigation_tabs.${tabLabel}`);
                return (
                  <Tab key={`${tabLabel}-${index}`} label={label}/>
                )
              })}
            </Tabs>
            <div className={classes.grow} />
            <div className={classes.search}>
              <SearchInput
                onChange={event => this.setState({search: event.target.value})}
                onAdvancedPress={_ => {
                  this.props.history.push(`/search`);
                  this.setState({tab: 0});
                }}
                onSearchPress={_ => {
                  if(search === '') {
                    this.props.history.push(`/search`);
                  } else {
                    this.props.history.push(`/search/${search}`);
                  }
                  this.setState({tab: 0});
                }}
                value={search}
                onKeyDown={event => {
                  if(event.keyCode === 13) {
                    this.props.history.push(`/search/${search}`);
                    this.setState({tab: 0});
                  }
                }}
              />
            </div>
            <div className={classes.sectionDesktop}>
              <Button
                variant='contained'
                onClick={_ => this.props.history.push(`/upload`)}
                className={classes.button}
                color='primary'
              >
                < AddIcon />
                {t('secondary_header.button.upload_media')}
              </Button>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={_ => this.props.history.push(`/upload`)}
                color="primary">
                <AddIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

SecondaryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter((withStyles(styles)(withTranslation()(SecondaryHeader))));
