import React from 'react';

import Checkout from '../../components/subscribeCheckout';

const styles = {
    root : {
        display: 'flex',
        justifyContent: 'center'
    }
}

export default props => (
    <div style={styles.root}>
        <Checkout />
    </div>
)