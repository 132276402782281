const detectYoutubeLinkRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

const detectYoutubeEmbeddedLinkRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

const detectUrlRegex = /^(http(s)?:\/\/)/;

const detectVideoRegex = /^video.*/;

module.exports = {
  detectYoutubeLinkRegex,
  detectYoutubeEmbeddedLinkRegex,
  detectUrlRegex,
  detectVideoRegex
}
