import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import { withTranslation } from 'react-i18next'

import Message from '../message';
import API from '../../api/appsync';
import Progress from './progress';
import Upload from './upload';
import Preview from './uploadPreviewHelpers';
import LinkInput from './linkInput';

const styles = theme => ({
    progress: {
        margin: '20% auto',
    },
        progressSmall: {
        margin: '-266px auto 0'
    },
    title: {
        fontSize: 18,
        fontWeight: 500,
        color: '#152939'
    },
    text: {
        fontSize: 16,
        fontWeight: 400,
        marginTop: 4,
        marginBottom: 24,
        color: '#828282'
    }
});

class ImageCreator extends React.Component {

    state = {
        uploading: false,
        progress: 0,
        error: null
    }

    onPick = async (data, fromLink) => {
        const {onDone} = this.props
        let body;

        if (fromLink) {
            body = {url: data};
        } else {
            this.setState({uploading: true, progress: 0});

            const progressCallback = progress => {
                this.setState({
                    progress: progress.loaded/progress.total
                });
            }

            body = await API.uploadFile({progressCallback, data});
        }

        if (onDone) {
            onDone('image', {
                body,
                preview: Preview({type: 'image', url: body})
            })
        }
    }

    render() {
        const {t} = this.props;
        const {uploading, progress, error} = this.state;
        if (uploading) {
            return <Progress progress={progress/100}/>;
        }
        return (
        <>
            {error !== null &&
                <Message type='error' message={error} onClose={_ => this.setState({error: null})}/>
            }
            <Upload
                content={<ImageIcon fontSize='inherit'/>}
                onPick={this.onPick}
                accept="image/*;capture=camcorder"
                text={t('upload.image.drag_and_drop')}
                title="Image"
                extraContent={
                    <LinkInput
                        onChange={(value) => this.onPick(value, true)}
                    />
                }
            />
        </>
        );
    }
}

export default withStyles(styles)(withTranslation()(ImageCreator));
