/*global AWS_CONFIG */
/*eslint no-undef: "error"*/
import {Auth, API, Storage, graphqlOperation} from 'aws-amplify';
import axios from 'axios';
import AWS from 'aws-sdk';
import jwt_decode from 'jwt-decode';
import {pickBy, isEmpty, isArray} from 'lodash';

import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';

const config = AWS_CONFIG;

async function getOwnerSubValue(username) {
    try {
        let users;
        const credentials = await Auth.currentCredentials();

        const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: config.aws_cognito_region,
            credentials: Auth.essentialCredentials(credentials)
        });

        const params = {
            UserPoolId: config.aws_user_pools_id,
            Filter: `preferred_username = "${username}"`,
            Limit: 1
        };

        const {Users} = await cognitoIdentityServiceProvider.listUsers(params).promise();
        users = Users;

        if (!users.length) {
            const params = {
                UserPoolId: config.aws_user_pools_id,
                Username: username
            };
            const {UserAttributes} = await cognitoIdentityServiceProvider.adminGetUser(params).promise();

            const {Value: sub} = UserAttributes.find(({Name}) => (Name === 'sub'));
            return sub;
        }

        const user = users[0];
        const {Attributes} = user;

        const {Value: sub} = Attributes.find(({Name}) => {
            return (Name === 'sub')
        });

        return sub;
    } catch (err) {
        return ''
    }
}

function extractUsernameFromAttributes(Attributes) {
    try {
        const {Value: username} = Attributes.find(({Name}) => {
            return (Name === 'preferred_username')
        });

        if (!username) {
            throw new Error('No username found in attributes');
        }

        return username
    } catch (err) {
        throw err;
    }
}

async function getUserNameBySub(sub) {
    let owner_picture = '';
    try {
        const credentials = await Auth.currentCredentials();

        const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: config.aws_cognito_region,
            credentials: Auth.essentialCredentials(credentials)
        });

        const params = {
            UserPoolId: config.aws_user_pools_id,
            Filter: `sub = "${sub}"`,
            Limit: 1
        };

        const {Users} = await cognitoIdentityServiceProvider.listUsers(params).promise();

        if (Users.length) {
            const user = Users[0];
            const {Attributes, Username} = user;
            const pictureObj = Attributes.find(({Name}) => {
                return (Name === 'picture')
            });

            if (pictureObj) {
                const {Value} = pictureObj
                owner_picture = Value;
            }

            let username;

            try {
                username = extractUsernameFromAttributes(Attributes);
            } catch (err) {
                username = Username;
            }

            return {owner_username: username, owner_picture};
        }

        return {owner_username: sub, owner_picture};
    } catch (err) {
        return {owner_username: sub, owner_picture};
    }
}

let exp = {};

exp.logout = _ => {
    Auth.signOut();
};

exp.getUsername = async _ => {
    let response = {};
    try {
        const curSesh = await Auth.currentSession();
        const token = curSesh.idToken.jwtToken;
        const decodedToken = jwt_decode(token);
        response.userName = decodedToken.preferred_username;
        response.userId = decodedToken.sub;
    } catch (e) {
        const attr = await Auth.currentUserInfo();
        response.userName = attr.username;
        response.userId = attr.attributes.sub;
    }

    return response;
};

exp.getUserNameBySub = async sub => getUserNameBySub(sub);

exp.getUserGroups = async () => {
    const session = await Auth.currentSession();

    if(!session.accessToken.payload.hasOwnProperty('cognito:groups')) {
        return false;
    }

    return session.accessToken.payload['cognito:groups'];
};

exp.canUserEdit = async () => {
    let isAdmin = false;
    let isCurator = false;
    const groups = await exp.getUserGroups();

    groups.forEach((group) => {
        if (group === 'Admins') {
            isAdmin = true;
        }

        if (group === 'Curators') {
            isCurator = true;
        }
    });

    return isAdmin || isCurator;
};

exp.isInGroup = async (group) => {
    const acceptedGroups = ['Admins', 'Curators', 'Teachers'];
    if (!acceptedGroups.includes(group)) {
        return false;
    }

    const groups = await exp.getUserGroups();
    return groups.includes(group);
};

exp.getUserInfo = async user => {
    return Promise.resolve({});
};

exp.getLocations = async _ => {
    const resp = await API.graphql(graphqlOperation(queries.listLocations));
    return resp.data.listLocations;
};

exp.getItemById = async id => {
    try {
        const {data: {getItem: item}} = await API.graphql(graphqlOperation(queries.getItem, {id}));
        return item;
    } catch (err) {
        console.log(err);
        return {}
    }
};

exp.search = async ({filter, from, size}) => {
    try {
        let items;
        if (filter && filter.owner) {
            const userSub = await getOwnerSubValue(filter.owner);
            if (userSub) {
                filter.owner = userSub
            }
        }
        const allItems = await API.graphql(graphqlOperation(queries.listItems, {
            from: from || 0,
            size: size || 10,
            filter
        }));

        const promiseList = allItems.data.listItems.items.map(async (item) => {
            const {owner} = item;
            const {owner_username, owner_picture} = await getUserNameBySub(owner);
            item.body = JSON.parse(item.body);
            item.location = JSON.parse(item.location);
            item.owner_username = owner_username;
            item.owner_picture = owner_picture;
            return item;
        });

        items = await Promise.all(promiseList);

        return {
            items
        }
    } catch (err) {
        return {items: []}
    }
};

exp.getAllTags = async _ => {
    const resp = await API.graphql(graphqlOperation(queries.listTags));
    return resp.data.listTags;
}

const getUsersItems = async (user, nextToken = null) => {
    console.log('User ', user);
    const userSub = await getOwnerSubValue(user);
    const filter = {
        owner: userSub
    };
    const allItems = await API.graphql(graphqlOperation(queries.listItems, {
        filter,
        from: 0,
        size: 1000
    }));

    return {
        items: allItems.data.listItems.items.map(item => {
            item.owner_username = user;
            item.body = JSON.parse(item.body);
            item.location = JSON.parse(item.location);
            return item;
        }),
        getNextItems: getUsersItems.bind(null, user, allItems.data.listItems.nextToken)
    };
};
exp.getUsersItems = getUsersItems;

exp.uploadFile = async ({progressCallback, data}) => {
    const name = `${new Date().getTime()}.${data.name}`;
    const resp = await Storage.put(name, data, {
        level: 'protected',
        contentType: data.type,
        progressCallback
    });

    let creds = await Auth.currentCredentials();

    return {
        url: `https://${config.aws_user_files_s3_bucket}/${creds.data.IdentityId}/${resp.key}`
    }
};

exp.getUserMeta = async _ => {
    const curSesh = await Auth.currentSession();
    const token = curSesh.idToken.jwtToken;
    const resp = await axios({
        method: 'post',
        url: API._options.aws_appsync_graphqlEndpoint,
        data: graphqlOperation(queries.getUserMeta),
        headers: {
            authorization: token
        }
    });
    return resp.data.data.getUserMeta;
}

exp.updateItem = async item => {
    const correctedItem = pickBy({
        ...item,
    }, ((el) => {
        if (isArray(el) && isEmpty(el)) {
            return false;
        }

        return (el != null && el !== '');
    }));

    let resp = await API.graphql(graphqlOperation(mutations.updateItem, {
        input: correctedItem
    }));
    let newItem = resp.data.updateItem;
    newItem.body = JSON.parse(newItem.body);
    newItem.location = JSON.parse(newItem.location);
    const {owner_username, owner_picture} = await getUserNameBySub(newItem.owner);
    newItem.owner_username = owner_username;
    newItem.owner_picture = owner_picture;
    return newItem;
}

exp.likeItem = async id => {
    await API.graphql(graphqlOperation(mutations.createItemLike, {
        id
    }));
}

exp.unlikeItem = async id => {
    await API.graphql(graphqlOperation(mutations.deleteItemLike, {
        id
    }));
}

exp.checkLikeItem = async id => {
    const resp = await API.graphql(graphqlOperation(queries.getLikeItem, {
        id
    }));
    return resp.data.getItemLike.liked;
}

exp.deleteItem = async id => {
    await API.graphql(graphqlOperation(mutations.deleteItem, {
        input: {id}
    }));
}

exp.linkDetail = async url => {
    const resp = await API.graphql(graphqlOperation(queries.getLinkDetail, {
        url
    }));
    return resp.data.getLinkDetail;
}

exp.createSubscription = async ({service, source}) => {
    const curSesh = await Auth.currentSession();
    const token = curSesh.idToken.jwtToken;
    const resp = await axios({
        method: 'post',
        url: API._options.aws_appsync_graphqlEndpoint,
        data: graphqlOperation(mutations.createSubscription, {
            input: {
                service,
                source: source.id
            }
        }),
        headers: {
            authorization: token
        }
    });
    if(resp.data.errors) {
        throw new Error(resp.data.errors[0].message);
    }
    return resp.data.data.createSubscription;
}

exp.subscribeToTranscription = async (jobId) => {
    try {
        return API.graphql(graphqlOperation(subscriptions.onCreateTranscript))
          .subscribe({
              next: (data) => {
                  console.log('DATA>>', data); // later perform filtering here with jobId
              }
          });
    } catch (err) {
        throw err;
    }
};

exp.federatedSearch = async ({filter, from, size, nextPageToken}) => {
    // search sources: googleCustomSearch, Youtube
    try {
        let params = {
            from: from || 0,
            size: size || 10,
            filter
        };

        if (nextPageToken) {
            params.nextPageToken = nextPageToken;
        }

        const result = await API.graphql(graphqlOperation(queries.listFederatedItems, params));

        return {
            items: result.data.listFederatedItems.items,
            nextPageToken: result.data.listFederatedItems.nextPageToken
        }
    } catch (err) {
        return {items: [], nextPageToken: null}
    }
};

exp.getSuggestedTags = async ({imageUrl, s3Url}) => {
    try {
        const params = {imageUrl, s3Url};
        const result = await API.graphql(graphqlOperation(queries.suggestTags, params));
        return result.data.getSuggestedTags.suggestedTags
    } catch (err) {
        console.log(err);
        return [];
    }
};

exp.listUsersBy = async ({searchAttribute, searchValue, limit = 5, paginationToken}) => {
    try {
        const allowedSearchAttributes = ['preferred_username', 'email', 'family_name', 'given_name'];

        if (!allowedSearchAttributes.includes(searchAttribute)) {
            return [];
        }

        const credentials = await Auth.currentCredentials();

        const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
            apiVersion: '2016-04-18',
            region: config.aws_cognito_region,
            credentials: Auth.essentialCredentials(credentials)
        });

        let params = {
            UserPoolId: config.aws_user_pools_id,
            Filter: `${searchAttribute} = "${searchValue}"`,
            Limit: limit
        };

        if (paginationToken) {
            params.PaginationToken = paginationToken;
        }

        const {Users, PaginationToken} = await cognitoIdentityServiceProvider.listUsers(params).promise();

        return {users: Users, nextPageToken: PaginationToken};
    } catch (err) {
        console.log(err);
        return [];
    }
}

export default exp;
