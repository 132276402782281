import React from 'react';
import Link from '../link';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: 250
    }
})

export default withStyles(styles)(props => (
    <div className={props.classes.root}>
        <Link {...props}/>
    </div>
))