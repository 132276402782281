import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import {compact, filter, size} from 'lodash';
import { connect } from 'react-redux';
import {DatePicker} from '@material-ui/pickers';
import { withTranslation } from 'react-i18next'
import classNames from 'classnames';

import CoverImage from './coverImage';
import API from '../../api/appsync';
import LayoutItemMap from '../map'
import RichTextEditor from './../textEditor/writeText'

const APP_CODE='BhshtshG-c0HHes4es1Arg';
const APP_ID='g6bgHsKVIObl57DcbYWe';

const chipStyles = theme => ({
    root: {
        margin: theme.spacing(),
    },
    chip: {
    },
});

const getPosition = options => {
    return new Promise(function (resolve, reject) {
        return navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};

const MyChip = withStyles(chipStyles)(props => {
    if(props.count) {
        return (
            <Badge className={props.classes.root} badgeContent={props.count} color="primary">
                <Chip
                    label={props.tag}
                    onDelete={props.onDelete}
                    className={props.classes.chip}
                />
            </Badge>
        )
    }
    return (
        <Chip
            label={props.tag}
            onDelete={props.onDelete}
            className={props.classes.root}
        />
    )
});

const styles = theme => ({
    root: {
        maxWidth: 500,
        flex: 1,
        margin: '0 auto'
    },
    textField: {},
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    ageRangeLabel: {
        flex: 1,
        marginRight: '10px',
        marginBottom: '10px'
    },
    ageRange: {
        width: '100%',
        maxWidth: 500,
    },
    FormControlRoot: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: '-16px'
    },
    radioGroup: {
        display: 'inline-table',
        padding: '0px'
    },
    selectAlignLeft: {
        marginLeft: '16px'
    },
    showMapItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px 0 20px',
        margin: 0,
        flexDirection: 'row-reverse',
        "& span": {
          fontSize: '0.875rem',
        }
    },
    mapWrapper: {
        border: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        marginBottom: theme.spacing(2),
    }
});
class UploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            latLon: null,
            transcription: '',
            coverImage: this.props.thumbnail || '',
            credit: this.props.isFederateSource ? this.props.body.url : '',
            location: null,
            count: {},
            premium: false,
            title: this.props.title || '',
            description: this.props.description || '',
            source: this.props.isFederateSource || 'wo hui go',
            licence: '',
            minimumAge: this.props.minimumAge || 1,
            maximumAge: this.props.maximumAge || 100,
            ageSliderValue: [1, 100],
            language: '',
            transcriptionType: 'auto',
            transcriptionVisibility: 'public',
            pinLocation: null,
            pinLatLon: null,
            courseCurriculum: '',
            upLoadedAt: null,
        };
    }

    getLocationFromCoordinates = async (lat, lng) => {
      const res = await axios.get(`https://reverse.geocoder.api.here.com/6.2/reversegeocode.json?app_id=${APP_ID}&app_code=${APP_CODE}&mode=retrieveAreas&prox=${lng},${lat}`);
      return res.data.Response.View[0].Result[0].Location.Address;
    };

    async componentDidMount() {
        const pos = await getPosition();
        const latLon = [pos.coords.longitude, pos.coords.latitude];
        const location = await this.getLocationFromCoordinates(latLon[0], latLon[1]);

        this.setState({location, latLon, pinLocation: location, pinLatLon: latLon});
    };

    submit = async _ => {
        const {onSubmit} = this.props;
        let {latLon, location, transcription, credit, coverImage, premium, title, description, source, licence, language, transcriptionType, transcriptionVisibility, pinLocation, pinLatLon, minimumAge, maximumAge, upLoadedAt} = this.state;

        if (pinLatLon !== latLon) {
            pinLocation = await this.getLocationFromCoordinates(pinLatLon[0], pinLatLon[1]);
        }

        const subMitParams = {
            latLon, location, transcription, credit, coverImage, premium, title, description, source, licence, language, transcriptionType, transcriptionVisibility, pinLocation, pinLatLon, minimumAge, maximumAge, upLoadedAt
        };

        onSubmit(subMitParams);
    };

    getTagCount = async term => {
        let {count} = this.state;
        const {items} = await API.search({tags: [term]});
        count[term] = items.length;
        this.setState({count});
    };

    onAdd = async (type, value) => {
        const {onAdd} = this.props;

        if (type === 'tags') {
            await this.getTagCount(value);
        }

        onAdd(type, value);
    };

    togglePremium = name => event => {
        this.setState({[name]: event.target.checked });
    };

    toggleAgeRangeSelect = async (event, newValue) => {
        this.setState({minimumAge: newValue[0], maximumAge: newValue[1], ageSliderValue: newValue});
    };

    setTranscriptionType = (event) => {
      this.setState({transcriptionType: event.target.value});
    };

    setTranscriptionVisibility = (event) => {
        this.setState({transcriptionVisibility: event.target.value});
    };

    setPinCords = ({lat, lng}) => {
        this.setState({pinLatLon: [lng, lat]});
    };

    toggleMap = () => {
        const {mapChecked} = this.state;
        this.setState({mapChecked: !mapChecked});
    }

    canCreateItem = () => {
        // NOTE: uncomment properties to make them required fields when creating an item
        const {tags} = this.props;
        // const {
        //     transcriptionType,
        //     transcription,
        //     title,
        //     description,
        //     source,
        //     language
        // } = this.state;

        const checkList = [
            tags,
            // courseCurriculumTags,
            // title,
            // description,
            // source,
            // language
        ];

        // if (transcriptionType === 'manual') {
        //     checkList.push(transcription);
        // }

        const hadEmpty = filter(compact(checkList), size);

        return !(hadEmpty.length === checkList.length);
    };

    render() {
        const {
            value,
            transcription,
            count,
            credit,
            premium,
            courseCurriculum,
            title,
            description,
            source,
            licence,
            ageSliderValue,
            language,
            transcriptionType,
            transcriptionVisibility,
            upLoadedAt,
            mapChecked,
        } = this.state;

        const {classes, preview, tags, courseCurriculumTags, onDelete, disabled, allowCredit, type, isAdmin, t} = this.props;

        return (
        <div className={classes.root}>
            {preview}

            {(type === 'audio' || type === 'text') &&
            <CoverImage
                onDone={coverImage => this.setState({coverImage})}
            />}

            <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup className={classes.radioGroup} name="transcriptionType" value={transcriptionType} onChange={this.setTranscriptionType}>
                    <FormControlLabel value="auto" control={<Radio color='secondary'/>} label={t('upload_form.labels.auto_trans')} />
                    <FormControlLabel value="manual" control={<Radio color='secondary'/>} label={t('upload_form.labels.manual_trans')} />
                </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup className={classes.radioGroup} name="transcriptionVisibility" value={transcriptionVisibility} onChange={this.setTranscriptionVisibility}>
                    <FormControlLabel value="private" control={<Radio color='secondary'/>} label={t('upload_form.labels.private_trans')} />
                    <FormControlLabel value="public" control={<Radio color='secondary'/>} label={t('upload_form.labels.public_trans')} />
                </RadioGroup>
            </FormControl>

            {transcriptionType === 'manual' &&
            <RichTextEditor
              label={t('upload_form.labels.transcription')}
              text={transcription}
              onChange={({markdown}) => this.setState({transcription: markdown})}
              readOnly={false}
            />}

            <TextField
              id="outlined-multiline-flexible-title"
              label={t('upload_form.labels.title')}
              fullWidth
              multiline
              value={title}
              onChange={event => this.setState({title: event.target.value})}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />

            <TextField
              id="outlined-multiline-flexible-description"
              label={t('upload_form.labels.description')}
              fullWidth
              multiline
              value={description}
              onChange={event => this.setState({description: event.target.value})}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />

            {allowCredit &&
            <TextField
                id="outlined-multiline-flexible-credit"
                label={t('upload_form.labels.credit')}
                fullWidth
                multiline
                value={credit}
                onChange={event => this.setState({credit: event.target.value})}
                className={classes.textField}
                margin="normal"
                variant="outlined"
            />}

            <TextField
              label={t('upload_form.labels.add_tag')}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                value={value}
                disabled={disabled}
                fullWidth
                onChange={event => this.setState({value: event.target.value})}
                onKeyDown={async event => {
                    if(event.keyCode === 13) {
                        await this.onAdd('tags', value);
                        this.setState({value: ''});
                    }
                }}
            />
            <br/>
            {tags.map((tag, index) => (
                <MyChip
                    key={`chip-${index}-${tag}`}
                    tag={tag}
                    onDelete={onDelete.bind(null, 'tags', tag)}
                />
            ))}

            <TextField
              label={t('upload_form.labels.add_course_curriculum')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={courseCurriculum}
              disabled={disabled}
              fullWidth
              onChange={event => this.setState({courseCurriculum: event.target.value})}
              onKeyDown={async event => {
                  if(event.keyCode === 13) {
                      await this.onAdd('courseCurriculum', courseCurriculum);
                      this.setState({courseCurriculum: ''});
                  }
              }}
            />
            <br/>
            {courseCurriculumTags.map((tag, index) => (
              <MyChip
                key={`chip-${index}-${tag}`}
                tag={tag}
                onDelete={onDelete.bind(null, 'courseCurriculum', tag)}
                count={count[tag]}
              />
            ))}
            <br/>

            <div className={ classes.mapWrapper }>
                <FormControlLabel
                control={<Switch checked={mapChecked} onChange={this.toggleMap} />}
                label={t('item.show_map')}
                className={classes.showMapItem}
                />
                <Slide direction="up" in={mapChecked} mountOnEnter unmountOnExit>
                <div className={classNames(classes.gridItemsLeft, classes.mapContainer)}>
                    <div className={classNames(classes.mapOverlay)}></div>
                    <LayoutItemMap setPinCords={this.setPinCords} isMarkerShown={false}/>
                </div>
                </Slide>
            </div>


            <TextField
              id="outlined-multiline-flexible-source"
              label={t('upload_form.labels.source')}
              fullWidth
              multiline
              value={source}
              onChange={event => this.setState({source: event.target.value})}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />

            <TextField
              id="outlined-multiline-flexible-licence"
              label={t('upload_form.labels.licence')}
              fullWidth
              multiline
              value={licence}
              onChange={event => this.setState({licence: event.target.value})}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />

            <div className={classes.ageRange}>
                <Typography id="range-slider" gutterBottom>
                    {t('upload_form.labels.age_range')}
                </Typography>
                <Slider
                  value={ageSliderValue}
                  onChange={this.toggleAgeRangeSelect}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(value) => `${value}`}
                />
            </div>

            <div className={classes.uploadDate}>
                <DatePicker
                  label={t('upload_form.labels.uploaded_date')}
                  value={upLoadedAt ? new Date(upLoadedAt) : null}
                  margin="normal"
                  variant="inline"
                  format="MM/DD/YYYY"
                  maxDate={new Date()}
                  className={classes.textField}
                  autoOk={true}
                  onChange={momentDate => {
                      const value = momentDate ? momentDate.format() : null;
                      this.setState({upLoadedAt: value});
                  }}
                />
            </div>

            <FormControl variant="outlined" className={classes.FormControlRoot}>
                <FormLabel component="legend" id="select-label-language" className={classes.selectAlignLeft}>{t('upload_form.labels.language')}</FormLabel>
                <Select
                  id="select-language"
                  className={classes.selectAlignLeft}
                  value={language}
                  onChange={event => this.setState({language: event.target.value})}
                >
                    <MenuItem value='GB'>{t('upload_form.options.english')}</MenuItem>
                    <MenuItem value='CN'>{t('upload_form.options.chinese')}</MenuItem>
                </Select>
            </FormControl>

            {isAdmin &&
            <FormGroup row>
                <FormControlLabel
                  control={
                      <Switch
                        checked={this.state.premium}
                        onChange={this.togglePremium('premium')}
                        value={premium}
                        color="primary"
                      />
                  }
                  label={t('upload_form.labels.premium_item')}
                />
            </FormGroup>}

            <Button
              disabled={this.canCreateItem() || disabled}
              onClick={this.submit}
              fullWidth
              color="primary"
              variant="contained"
              className={classes.button}>
                {t('upload_form.button.create')}
            </Button>
        </div>
        )
    }
}

const mapStateToProps = ({isAdmin}) => ({isAdmin});
export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(UploadForm)));
