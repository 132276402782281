import React from 'react';
import Text from '../text';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing()
    }
})

export default withStyles(styles)(props => (
    <div className={props.classes.root}>
        <Text
          text={props.body.text}
          richTextMarkdown={props.body.richTextMarkdown}
          richTextDraftJs={props.body.richTextDraftJs}
          maxLength={250}
          showPreview={props.showPreview}
        />
    </div>
))
