import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Auth0LoginButton from './../auth0LoginButton'
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { SignIn } from 'aws-amplify-react';
import theme from '../../themes';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Logo from '../../logos/orange-logo.png';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(25),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    '&:focus': {
      borderColor: 'primary.dark'
    }
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    margin: '25% auto'
  }
});

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent() {
    const {classes} = this.props;

    if (window.location.search.includes('code')) {
      return (
        <div className={classes.progress}>
          Loading...
        </div>
      )
    }

    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img src={Logo} alt="Logo" height={50} className={classes.avatar}/>
            <Typography component="h1" variant="h5">
              Wo Hui Go
            </Typography>
            <div className={classes.form} >
              <Auth0LoginButton/>
            </div>
          </div>
      </Container>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(CustomSignIn)
