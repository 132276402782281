import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

export default function CustomizedInputBase({onChange, onKeyDown, value, onSearchPress}) {
  const classes = useStyles();
  const { t } = useTranslation()

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="Search" onClick={onSearchPress}>
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={t('secondary_header.input.search_existing_media')}
        inputProps={{ 'aria-label': 'Search...' }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
      />
    </Paper>
  );
}
