import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next'

import LinkInput from './linkInput';
import {detectYoutubeLinkRegex, detectUrlRegex, detectYoutubeEmbeddedLinkRegex} from './uploadLinkHelpers';
import Preview from './uploadPreviewHelpers';

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      padding: '35px 40px!important',
      maxWidth: 460,
      minWidth: 150,
      width: '100%',
      margin: '5% auto 50px',
      cursor: 'pointer'
    },
    button: {
      borderRadius: 0,
      boxShadow: 'none',
      fontSize: 12,
      fontWeight: 400,
      padding: '14px 0'
    }
  });

class Youtube extends React.Component {
    state = {
        link: '',
        error: false,
        helperText: ''
    }

    submitYoutube = _ => {
        const {link} = this.state;
        const {onDone, t} = this.props;

        const match = link.match(detectYoutubeEmbeddedLinkRegex);
        const id = (match && match[7].length===11) ? match[7] : false;

        if (!id) {
          this.setState({helperText: `${t('upload.link.no_id_error')}`, error: true});
          return false;
        } else {
          this.setState({helperText: '', error: false});
        }

        onDone('youtube', {
            body: {youtubeId: id},
            preview: Preview({type: 'youtube', id})
        });
    }

    submit = _ => {
        const {link} = this.state;
        const {onDone} = this.props;

        if (detectYoutubeLinkRegex.test(link)) {
            this.submitYoutube();
            return;
        }

        let url = link;
        if (!detectUrlRegex.test(url)) {
            url = `http://${link}`;
        }
        onDone('link', {
            body: {url},
            preview: Preview({type: 'basicLink', url})
        });
    }

    render() {
        const {link} = this.state;
        const {classes, t} = this.props;
        return (
            <Paper className={classes.root} elevation={1}>
                <LinkInput
                  onChange={(value) => this.setState({link: value})}
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                    onClick={this.submit}
                    disabled={link === ''}
                >
                  {t('upload.link.button.create')}
                </Button>
            </Paper>
        )
    }
}

export default withStyles(styles)(withTranslation()(Youtube));
