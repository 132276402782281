import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next'

import Api from '../../api/appsync';
import LanguageSelector from '../languageSelector'
import Logo from '../../logos/white-logo.png';
import headerBackground from './header.svg'

const styles = theme => ({
  root: {
    width: '100%'
  },
  header: {
    background: 'transparent',
    backgroundImage: `url(${headerBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  sectionDesktop: {
    display: 'block'
  },
  alignSectionDesktop: {
    display: 'flex'
  }
});

class Header extends React.Component {
  state = {
    anchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = _ => {
    Api.logout();
  }

  render() {
    const { anchorEl } = this.state;
    const { classes, t } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleLogout}>{t('header.button.logout')}</MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static" elevation={0} className={classes.header}>
          <Toolbar>
            <Link to='/search'>
              <img src={Logo} alt="Logo" height={30}/>
            </Link>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <div className={classes.alignSectionDesktop}>
                <LanguageSelector/>
                <IconButton
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(Header)));
