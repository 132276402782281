import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Api from './../../api/appsync';

const useStyles = makeStyles(theme => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const UserSearchDropDown = ({setSearchedUser}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);

  const constructUsers = (user) => {
    const {Attributes, Username} = user;
    let userAttributes = Attributes.reduce((acc, att) => {
      const {Name, Value} = att;
      return {...acc, [`${Name}`]: `${Value}`}
    }, {});

    return {
      ...userAttributes,
      username: Username
    }
  }

  const searchUsers = async (event, value) => {
    try {
      const params = {
        searchAttribute: 'preferred_username',
        searchValue: value,
        paginationToken: nextPageToken,
        limit: 50
      };

      const {users: _users, nextPageToken: nextToken} = await Api.listUsersBy(params);

      if (_users) {
        const constructedUsers = _users.map(constructUsers);
        setUsers([...new Set(constructedUsers)]);
      }

      if (nextToken) {
        setNextPageToken(nextToken);
      }
    } catch (err) {
      setUsers([])
    }
  }

  return (
    <Autocomplete
      id="user-search-drop-down-menu"
      classes={{
        option: classes.option,
        root: classes.root
      }}
      options={users}
      getOptionLabel={(option) => {
        return `${option.preferred_username}`;
      }}
      getOptionSelected={(option, value) => option.preferred_username === value.preferred_username}
      renderOption={(option) => (
        <React.Fragment>
            <span>
              <Avatar src={option.picture}/>
            </span>
          {option.preferred_username}
        </React.Fragment>
      )}
      onInputChange={searchUsers}
      renderInput={(params) => <TextField {...params} label={t('user_search.uploaded_by')} margin="normal" />}
      onChange={(event, newValue) => {
        if (!newValue) {
          return setSearchedUser('');
        }

        if (newValue && newValue.sub) {
          const {sub} = newValue;
          setSearchedUser(sub); // change this attribute to change search owner value
        }
      }}
    />
  )
}

export default UserSearchDropDown;
