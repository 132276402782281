import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as mutations from '../../graphql/mutations';
import {API, graphqlOperation} from 'aws-amplify';
import UUID from 'uuid/v1';
import {withRouter} from 'react-router-dom';
import {pickBy, isEmpty, isArray} from 'lodash';

import ImageUpload from './image';
import Link from './link';
import Text from './text';
import Video from './video';
import Audio from './audio';
import UploadForm from './uploadForm';

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    }
});

class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: props.result || null,
            type: props.type || null,
            premium: false,
            tags: props.suggestedTags || [],
            courseCurriculum: [],
            submitting: false,
            status: 'transcription_pending'
        };
    }

    onDelete = (type, delTag) => {
        const target = this.state[type];
        const currentIndex = target.indexOf(delTag);
        const newList = [...target];

        if (currentIndex === -1) {
            return;
        }

        newList.splice(currentIndex, 1);
        this.setState({[`${type}`]: newList});
    };

    onAdd = (type, newTag) => {
        let tag = newTag;
        const target = this.state[type];

        if (type === 'courseCurriculum') {
            tag = newTag.toLowerCase();
        }

        const newList = [...target, tag];
        this.setState({[`${type}`]: newList});
    };

    submit = async (params) => {
        let {
            location,
            transcription,
            credit,
            pinLocation,
            coverImage,
            minimumAge,
            maximumAge
        } = params;

        const {result, tags, type, status, courseCurriculum} = this.state;
        const {body} = result;
        let itemStatus = status;

        if (transcription === '') {
            transcription = null;
        }

        if (transcription) {
            itemStatus = 'pending_approval';
        }

        if (credit === '') {
            credit = null;
        }

        if (coverImage === '') {
            coverImage = null;
        }

        this.setState({submitting: true});

        const itemDetails = pickBy({
            ...params,
            id: UUID(),
            tags,
            type,
            credit,
            body: JSON.stringify(body),
            location: JSON.stringify(location),
            status: itemStatus,
            pinLocation: JSON.stringify(pinLocation),
            courseCurriculum,
            minimumAge,
            maximumAge,
            transcription,
            coverImage
        }, ((el) => {
            if (isArray(el) && isEmpty(el)) {
                return false;
            }

            return (el != null && el !== '');
        }));

        await API.graphql(graphqlOperation(mutations.createItem, {input: itemDetails}));

        //TODO: Fix the need to randomly refresh after item created --> Needs to add item to list of items belonging to user
        this.props.history.push('/profile/me');
    };

    render() {
        const {result, tags, submitting, courseCurriculum} = this.state;
        const {type} = this.props;
        if(result) {
            return (
            <>
                <UploadForm
                    onDelete={this.onDelete}
                    onAdd={this.onAdd}
                    onSubmit={this.submit}
                    tags={tags}
                    courseCurriculumTags={courseCurriculum}
                    type={type}
                    disabled={submitting}
                    allowCredit={type !== 'link'}
                    {...result}
                />
            </>
            );
        }

        switch(type) {
            default:
                return <div>None</div>;
            case 'audio':
                return (
                    <Audio
                        onDone={(type, result) => this.setState({type, result})}/>
                );
            case 'video':
                return (
                    <Video
                        onDone={(type, result) => this.setState({type, result})}/>
                );
            case 'youtube':
                return (
                    <Link
                        onDone={(type, result) => this.setState({type, result})}/>
                );
            case 'text':
                return (
                    <Text
                        onDone={(type, result) => this.setState({type, result})}/>
                );
            case 'image':
                return (
                    <ImageUpload
                        onDone={(type, result) => this.setState({type, result})}/>
                );
        }
    }
}

export default withRouter(withStyles(styles)(Upload));
