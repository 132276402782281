import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    progress: {
      margin: '20% auto',
    },
    progressSmall: {
      margin: '-266px auto 0'
    }
});

export default withStyles(styles)(({progress, classes}) => (
    <>
        <CircularProgress
            className={classes.progress}
            variant="determinate"
            value={progress}
            size={80}
        />
        <CircularProgress
            size={67}
            className={classes.progressSmall}
            color="secondary" />
    </>
))