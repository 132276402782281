import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedFederatedInputButtonsGroup(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState('all');

  const handleChange = event => {
    const {target: {value}} = event;
    setValue(value);
    props.setType(value)
  };

  return (
    <div>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup aria-label="type" name="type1" value={value} onChange={handleChange} row>
          <FormControlLabel value="all" control={<Radio />} label={t('federated_search.radio_filter.radio.labels.all')} />
          <FormControlLabel value="image" control={<Radio />} label={t('federated_search.radio_filter.radio.labels.images')} />
          <FormControlLabel value="video" control={<Radio />} label={t('federated_search.radio_filter.radio.labels.videos')} />
          {/*<FormControlLabel value="audio" control={<Radio />} label="Audio" />*/}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
