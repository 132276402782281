import React from 'react';

import Image from './image';
import Youtube from './youtube';
import Video from './video';
import Audio from './audio';
import Link from './link';
import Text from './text';

const style = {
  coverImage: {
    width: '100%',
    objectFit: 'cover'
  }
}

export default (props) => {
  const {tags, body, type, coverImage, coverImageThumb} = props;
  let _body;
  try {
    _body = JSON.parse(body);
  } catch (err) {
    _body = body;
  }
  let media = null;
  switch(type) {
    case 'link':
      media = <Link tags={tags} body={_body} />;
      break;
    case 'audio':
      media = <Audio tags={tags} body={_body} noHeight={coverImage ? true : false}/>;
      break;
    case 'video':
      const {youtubeId} = _body;
      if (youtubeId) {
        media = <Youtube tags={tags} body={_body} />;
      } else {
        media = <Video tags={tags} body={_body} />;
      }
      break;
    case 'image':
      media = <Image tags={tags} body={_body} />;
      break;
    case 'text':
      media = <Text tags={tags} body={_body} showPreview={typeof coverImage !== 'string'}/>;
      break;
    case 'youtube':
      media = <Youtube tags={tags} body={_body} />;
      break;
    default:
      return null;
  }
  return (
    <>
      {coverImage &&
        <img style={style.coverImage} src={coverImageThumb || coverImage} alt="cover" />
      }
      {media}
    </>
  )
}
