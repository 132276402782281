export const getItem = `query GetItem(
  $id: ID!
) {
  getItem(id: $id) {
    id
    owner
    createdAt
    credit
    type
    tags
    transcription
    body
    location
    latLon
    coverImage
    coverImageThumb
    likes
    status
    title
    description
    source
    licence
    maximumAge
    minimumAge
    language
    transcriptionType
    transcriptionVisibility
    pinLocation
    pinLatLon
    charactersBreakdown,
    courseCurriculum
    upLoadedAt
  }
}
`;

export const listItems = `query ListItems(
  $filter: ModelItemFilterInput
  $from: Int
  $size: Int
) {
  listItems(filter: $filter, from: $from, size: $size) {
    items {
      id
      owner
      createdAt
      credit
      type
      tags
      transcription
      body
      location
      latLon
      coverImage
      coverImageThumb
      likes
      status
      title
      description
      source
      licence
      maximumAge
      minimumAge
      language
      transcriptionType
      transcriptionVisibility
      pinLocation
      pinLatLon
      charactersBreakdown
      courseCurriculum
      upLoadedAt
      totalNumberOfChars
      title
      description
    }
  }
}
`;
export const listFederatedItems = `query ListFederatedItems(
  $filter: ModelItemFederatedInput
  $from: Int
  $size: Int
  $nextPageToken: String
) {
  listFederatedItems(filter: $filter, from: $from, size: $size, nextPageToken: $nextPageToken) {
    items {
      type
      url
      title
      source
      thumbnail
    }
    nextPageToken
  }
}
`;
export const listLocations = `query ListLocations(
  $from: Int
  $size: Int
) {
  listLocations(from: $from, size: $size) {
    label
  }
}
`;
export const getLinkDetail = `query GetLinkDetail(
  $url: String!
) {
  getLinkDetail(url: $url)  {
    url
    description
    image
    imageWidth
    imageHeight
    imageType
    title
    ogVideoUrl
    ogUrl
    youtube
  }
}
`;
export const getLikeItem = `query GetItemLike(
  $id: ID!
) {
  getItemLike(id: $id) {
    liked
  }
}
`;
export const getUserMeta = `query GetUserMeta {
  getUserMeta {
    whgSubEnd
  }
}
`;
export const listTags = `query ListTags {
  listTags {
    value
    text
  }
}
`;

export const suggestTags = `query SuggestTags(
  $imageUrl: String
  $s3Url: String
) {
  getSuggestedTags(imageUrl: $imageUrl, s3Url: $s3Url) {
    suggestedTags
  }
}`;
