import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import PageSearch from './pages/search';
import PageUpload from './pages/upload';
import PageProfile from './pages/profile';
import PageTagCloud from './pages/tagCloud';
import PageSubscribe from './pages/subscribe';
import FederatedSearch from './pages/federatedSearch';
import PageItemView from './pages/item/view';

function AppRouter() {
  return (
    <Switch>
      <Route path="/search/:term"             component={PageSearch}/>
      <Route path="/search"                   component={PageSearch}/>
      <Route path="/upload/:type"             component={PageUpload}/>
      <Route path="/upload"                   component={PageUpload}/>
      <Route path="/profile/:userId"          component={PageProfile}/>
      <Route path="/federated-search"         component={FederatedSearch}/>
      <Route path="/tag"                      component={PageTagCloud}/>
      <Route path="/subscribe"                component={PageSubscribe}/>
      <Route path="/item/view/:itemId"        component={PageItemView}/>
      <Route path="/" exact>
        <Redirect to="/search" />
      </Route>
    </Switch>
  );
}

export default AppRouter;
