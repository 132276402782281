import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import EnglishIcon from './icons/English.svg';
import ChineseIcon from './icons/Character.svg';

const useStyles = makeStyles((theme) => ({
  imageIcon: {
    height: '100%',
    padding: 0,
    margin: '0 auto',
  },
  iconRoot: {
    textAlign: 'center',
    fontSize: 'medium',
    padding: 0,
    margin: '0 auto'
  }
}));

export default function LanguageSelector (props) {
  const {lng} = props;
  const classes = useStyles();
  let icon;

  switch (lng) {
    case 'GB':
      icon = (
        <Icon classes={{root: classes.iconRoot}}>
          <img className={classes.imageIcon} alt='' src={EnglishIcon}/>
        </Icon>
      );
      break;
    case 'CN':
      icon = (
        <Icon classes={{root: classes.iconRoot}}>
          <img className={classes.imageIcon} alt='' src={ChineseIcon}/>
        </Icon>
      );
      break;
    default:
      icon = null;
  }

  return icon;
};
